import { Component, OnInit } from '@angular/core';
import {DropdownOption, programOption} from '../models/dropdown-option';
import {EpswHomepageService} from '../services/epsw-homepage.service';
import {AdhocReportService} from '../services/adhoc-report.service';

@Component({
  selector: 'adhoc-report',
  templateUrl: './adhoc-report.component.html',
  styleUrls: ['./adhoc-report.component.scss']
})
export class AdhocReportComponent implements OnInit {
	programOption: DropdownOption[] = programOption;
	programData: any;
	programDetails: DropdownOption[] = [];
	selectedProgram: any;
	private dataValue: any;
	programType: any;
	siteEngineer: any;
	siteSupervisor: any;
	siteManager: any;
	staRole: string;
	staCdsids: string[];
	selectedBy= 'Program';
	style = { width: '250px' }; // Or any width you desire



	constructor(private epswHomepageService: EpswHomepageService, private adhocReportService: AdhocReportService) {
	  this.programType=true;
	}

  ngOnInit(): void {

  }

	onChangingProgram(selectedProgram: any) {
		console.log('33');
		console.log(selectedProgram);
		console.log(this.programData);
		if (selectedProgram.name === 'By Program') {
			this.selectedBy= 'Program';
			this.epswHomepageService.getProgramCodeValues().subscribe(value => {
				console.log(value);
				this.dataValue = value.mapOfStringListMap['programList'];
				console.log(this.dataValue);
				let dummy : DropdownOption[] = [];
				for (const dataOne of this.dataValue) {
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						console.log(dataOne);
						console.log(dataOne[1]);
						const con = dataOne['tepsw_batch_001_program_code']+'-'+dataOne['tepsw_batch_001_program_name'];
						dummy.push({
							name: con,
							code: con
						});

					}
				}
				console.log(this.programDetails);
				this.programDetails = dummy;
				console.log(this.programDetails);
			});
		}else if (selectedProgram.name === 'By Site Engineer') {
			this.selectedBy= 'Engineer CDSIDs';
			this.staRole = 'staEngineer';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)
				this.siteEngineer = true;
				this.programType = false;

			});
		} else if (selectedProgram.name === 'By Site Supervisor') {
			this.selectedBy= 'Supervisor CDSIDs';
			this.staRole = 'staSupervisor';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)
				this.siteEngineer = true;
				this.programType = false;
			});
		} else if (selectedProgram.name === 'By Site Manager') {
			this.selectedBy= 'Manager CDSIDs';
			this.staRole = 'staManager';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)

				this.siteEngineer = true;
				this.programType = false;
			});
		} else {
			this.selectedProgram
		}
	}

	getProgramDetails(selectedProgram: any) {
		if(this.siteEngineer === true) {
			this.adhocReportService.generateExcelForSTASiteEngineer(selectedProgram.name);
		}
		if(this.siteManager === true) {

		}
		if(this.siteManager === true) {

		}

  }

	getDetailsInExcel() {
		if(this.siteEngineer === true) {
			this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name);
		}
		if(this.siteManager === true) {
			this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name);
		}
		if(this.siteManager === true) {
			this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name);
		}
		if(this.selectedBy === 'Program') {
			let split = this.selectedProgram.name.split('-');
			var splitElement = split[1];
			this.adhocReportService.generateExcelForSTASiteEngineer(splitElement);
			//this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name);
		}

	}

	getCancel() {
     this.programData = '';
	 this.selectedProgram='';
	}
}
