import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ConfirmationService, SelectItem, Message} from 'primeng/api';
import * as  Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
import Exporting from 'highcharts/modules/exporting';
import {EpswHomepageService} from '../services/epsw-homepage.service';
import {DropdownOption, ppapLevel, ppapPhaseOption, priorityOption} from '../models/dropdown-option';
import {AllPPAPPhase} from '../models/allPPAPPhase';
import {CmmsPartsPpap} from '../models/cmms-parts-ppap';
import {MessageService} from 'primeng';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {PpapPackageIdentifier} from '../models/ppap-package-identifier';
import {PpapPackageParts} from '../models/ppap-package-parts';
import {RoleBasedAccessProviderService} from '../psw-approval-flow/role-based-access-provider.service';
import {DigitalPpapPackageService} from '../services/digital-ppap-package.service';
import {SupplierViewService} from '../services/supplier-view.service';
import {HttpClient} from "@angular/common/http";

More(Highcharts);

Tree(Highcharts);

Heatmap(Highcharts);
// Initialize exporting module.
Exporting(Highcharts);

interface Ppapdetail {
	ppapcategory: string;
	pgm: string;
	prefix: string;
	base: string;
	suffix: string;
	partdescription: string;
	changeintiming: string;
	rardt: string;
	rcheckout: string;
	qualdt: string;
	qcheckout: string;
	proddt: string;
	pcheckout: string;
	capdt: string;
	status: string;
	level: string;
}

@Component({
	selector: 'epsw-homepage',
	templateUrl: './epsw-homepage.component.html',
	styleUrls: ['./epsw-homepage.component.scss']
})
export class EpswHomepageComponent implements OnInit {
	@ViewChild('container', {static: true}) container: ElementRef;
	treeMap: any[];

	styles =
		{
			pgm: '100px',
			prefix: '70px',
			suffix: '100px',
			base: '70px',
			partdescription: '250px',
			rardt: '95px',
			qualdt: '95px',
			proddt: '95px',
			capdt: '95px',
			status: '100px',
			level: '90px'
		};
	//supplier
	simppapLevel: any;
	supplier: any;
	supplierName : any;
	ppapTabDetail: Ppapdetail[];
	cols: any[];
	ppapCategoryOption: SelectItem[];
	site: SelectItem[];
	siteList:string[]=[];
	siteValues:string[]=[];
	ppapOption: DropdownOption;
	siteOption: any;
	programCodeSiteCodes:string[]=[];
	ppapPhaseOption: DropdownOption[] = ppapPhaseOption;
	priorityOption: DropdownOption[] = priorityOption;
	ppapPhase: DropdownOption;
	priorityType: DropdownOption;
	selectedPriorityType: string;
	ppapLevel: DropdownOption[] = ppapLevel;
	ppapLevelOption: DropdownOption;
	selectedPrifix: any[];
	noOfPrg: string;
	commodities: string;
	selectDue: string;
	allPPAPPhase : AllPPAPPhase;
	selectedHeading: boolean;
	siteCodeList: string[]=[];
	beyondSixMonths: any;
	pastDue: any;
	nextSixMonths: any;
	vppUntimed: any;
	cmmsPartsPPap: CmmsPartsPpap[];
	cmmsPartsPPapFiltered: CmmsPartsPpap[];
	status: any;
	ppapPackageIdentifierSave: PpapPackageIdentifier;
	phase: string;
	ppapPackageParts: PpapPackageParts[] =[];
	ppapElementSection: any[];
	ppapElementQuestion: any[];
	selectedPpapParts: any[]=[];
	ppapPackagePartsForBaseCheck: PpapPackageParts[]=[];
	ppapPackagePartsForPPAPLevel: PpapPackageParts[]=[];
	countOfBase: any;
	countOfPPAPLevel: any;
	//Save plan
	private plan: any;
	planDisplay: any;
	phaseDisplay: any;
	userRole: string;
	initSiteCodes: string[]=[];
	apsAttributes: any;
	matrixDisplayUpdate = true;
	ppapLevelOverrideDisplay = true;
	selectCheckBoxVisibility = false;
	supplierAccess = false;
	sessionStorageData: any = sessionStorage;
	pswAccessControl: Map<string,any> = new Map<string, any>();
	siteCodes: string[]=[];
	button1  : boolean ; button2  : boolean ; button3  : boolean ; button4  : boolean ;
	button5  : boolean ; button6  : boolean ; button7  : boolean ; button8  : boolean ;
	button9  : boolean ; button10 : boolean ; button11 : boolean ; button12 : boolean ;
	button13 : boolean ; button14 : boolean ; button15 : boolean ; button16 : boolean ;
	button17 : boolean ; button18 : boolean ; button19 : boolean ; button20 : boolean ;
	button21 : boolean ; button22 : boolean ; button23 : boolean ; button24 : boolean ;
	button25 : boolean ; button26 : boolean ; button27 : boolean ; button28 : boolean ;
	button29 : boolean ; button30 : boolean ; button31 : boolean ; button32 : boolean ;
	button33 : boolean ; button34 : boolean ; button35 : boolean ; button36 : boolean ;
	button37 : boolean ; button38 : boolean ; button39 : boolean ; button40 : boolean ;
	button41 : boolean ; button42 : boolean ; button43 : boolean ; button44 : boolean ;
	private ppapPackageIdentifierId: any;
	private displayMessage: Message[] = [];
	ppapOptionSession: string;
	ppapOptionCode: string;
	//Added for US3832060
	displayCommentsForNoActionRequired: any;
	noActionRequiredComments: string;
	ppapPartsData: PpapPackageParts;
	entityID: any;
	dispalySavedComments = false;
	private checkStatus: string;
	isdisableNA = true;
	partsArray: any[]=[];
	checkedStatus: boolean;
	showPDApprovalCol = false;
	checked1 : boolean;
	checked2 : boolean;
	viewBy : boolean;
	programOption: SelectItem[];
	programData: any
	showSiteValues = false;
	showProgramValues = true;
	private dataValue: any;
	programDescription: any;
	programCodes: any;
	private programCodeList: any[];
	totalPartCount: any;
	priorityListResp: any[] = [];
	s: string;
	enableReportDownload: boolean= false;



	constructor(private http: HttpClient,private messageService: MessageService,private epswHomepageService: EpswHomepageService,private renderer: Renderer2,
				private confirmationService: ConfirmationService, private router: Router, public route: ActivatedRoute,
				private digitalPpapPackageService: DigitalPpapPackageService,private supplierViewService: SupplierViewService) {
		this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
		this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
		this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
		this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
		this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
		this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
		this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
		this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
		this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
		this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
		this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		this.status = 'PENDING';
		this.selectedHeading = false;
		this.ppapPackageIdentifierId = this.route.snapshot.paramMap.get('id');
		if (sessionStorage.getItem('attributes') !== null) {
			this.apsAttributes = sessionStorage.getItem('attributes');
			this.userRole = JSON.parse(this.apsAttributes).userRole;
			console.log('userRole',this.userRole)
			this.initSiteCodes = JSON.parse(this.apsAttributes).siteCodes;
			this.pswAccessControl = RoleBasedAccessProviderService.getAccessForSupplierRole(this.userRole);
			this.selectedPriorityType = sessionStorage.getItem('priorityType');
			if(this.selectedPriorityType === null || this.selectedPriorityType === 'ALL') {
				this.priorityType={name: 'All', code: 'ALL'};
			}
			if(this.selectedPriorityType === 'PR') {
				this.priorityType={name: 'Priority', code: 'PR'};
			}
			if(this.selectedPriorityType === 'NPR') {
				this.priorityType={name: 'Non Priority', code: 'NPR'};
			}
			if (this.pswAccessControl.get('isSupplier')) {
				this.ppapOption = {name: 'All', code: 'ALLPPAP'};
				this.ppapCategoryOption = [
					{label: 'All', value: {name: 'All', code: 'ALLPPAP'}},
					{label: 'Forward Model PPAP', value: {name: 'Forward Model PPAP', code: 'APPAP'}},
					{label: 'Running Change PPAP', value: {name: 'Running Change PPAP', code: 'RCPPAP'}},
				];
			} else {
				this.ppapOption = {name: 'All', code: 'ALLPPAP'};
				this.ppapCategoryOption = [
					{label: 'All', value: {name: 'All', code: 'ALLPPAP'}},
					{label: 'Forward Model PPAP', value: {name: 'Forward Model PPAP', code: 'APPAP'}},
					{label: 'Running Change PPAP', value: {name: 'Running Change PPAP', code: 'RCPPAP'}},
				];
				if(this.pswAccessControl.get('isPDEngineer')){
					this.showPDApprovalCol = true;
				} else {
					this.showPDApprovalCol = false;
				}
			}
		}
		if (!sessionStorage.getItem('viewBy')) {
			if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
				|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser'||this.userRole === 'ePSW View Only') {
				sessionStorage.setItem('viewBy', 'program');
			} else if(this.userRole === 'STASiteEngineer' || this.userRole === 'PDEngineer' ||
				this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
				this.userRole === 'APQPSiteSTAEngineer'){
				sessionStorage.setItem('viewBy', 'site');
			}
		}
		if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
			|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser'|| this.userRole ==='ePSW View Only'){
			this.checked1 = true;
			this.checked2 = false;
			this.viewBy = true;
			this.showProgramValues = true;
			this.showSiteValues = false;
		} else if(this.userRole === 'STASiteEngineer' || this.userRole === 'PDEngineer' ||
			this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
			this.userRole === 'APQPSiteSTAEngineer'){
			this.checked1 = false;
			this.checked2 = true;
			this.viewBy = false;
			this.showProgramValues = false;
			this.showSiteValues = true;
		}
		if (this.ppapPackageIdentifierId !== null && this.ppapPackageIdentifierId !== undefined) {
			this.epswHomepageService.getDataOfSelectedRow(this.ppapPackageIdentifierId).subscribe(pswResponse => {
				//this.site.push({label: respOne.siteCode, value: respOne.siteCode});
				const respOne = pswResponse.ppapPackageIdentifierEntity;
				console.log('viewBy',sessionStorage.getItem('viewBy'));
				if (sessionStorage.getItem('viewBy') === 'site') {
					this.checked2 = true;
					this.checked1 = false;
					this.showProgramValues = false;
					this.showSiteValues = true;
					if (sessionStorage.getItem('siteOption') === 'ALL') {
						this.siteOption = sessionStorage.getItem('siteOption');
					} else {
						this.siteOption = respOne.siteCode;
					}
					// this.ppapCategoryOption = [{label: sessionStorage.getItem('ppapOption'),
					// 	value: {id: 1, name: sessionStorage.getItem('ppapOption'), code: 'APPAP'}}];
					// this.ppapOption = {name: sessionStorage.getItem('ppapOption'), code: sessionStorage.getItem('ppapOptionCode')};
				this.ppapOption = {name: sessionStorage.getItem('ppapOption'), code: sessionStorage.getItem('ppapOptionCode')};
					//alert('1'+this.ppapOption.name);
					this.getNoOfProgram(this.siteOption);
					this.getNoOfCommodities(this.siteOption);
					this.getSupplierName(this.siteOption);
					this.getTreeMapValues(this.siteOption);
					// Adding All Option in PPAP Timing
					respOne.ppapPhaseCode = sessionStorage.getItem('ppapTiming');
					if (respOne.ppapPhaseCode === 'PNS') {
						this.ppapPhase = {name: 'Next 6 Month', code: 'PNS'};
					}
					if (respOne.ppapPhaseCode === 'PBS') {
						this.ppapPhase = {name: 'Beyond Six Month', code: 'PBS'};
					}
					if (respOne.ppapPhaseCode === 'PTU') {
						this.ppapPhase = {name: 'Parts Untimed', code: 'PTU'};
					}
					if (respOne.ppapPhaseCode === 'PPD') {
						this.ppapPhase = {name: 'Past Due', code: 'PPD'};
					}
					// Adding All Option in PPAP Timing
					if (respOne.ppapPhaseCode === 'ALL') {
						this.ppapPhase = {name: 'All', code: 'ALL'};
					}
					if (this.siteOption != null && this.siteOption !== undefined) {
						if (this.ppapPhase.code === 'ALL') {
							console.log('ALL Type PPAP Phase');
							// this.getAllPPAPPhase(this.siteOption);
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getAllPPAPTiming(this.siteCodes);
							}
						}
						if (this.ppapPhase.code === 'PBS') {
							console.log('Beyond Six Month Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getBeyondSixPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getBeyondSixPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PNS') {
							console.log('Next Six Month Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getNextSixPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getNextSixPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PTU') {
							console.log('Parts Untimed Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getPartsUntimedPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getPartsUntimedPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PPD') {
							console.log('Past Due Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getPastDuePPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getPastDuePPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
					}
					if (this.siteOption != null && this.siteOption !== undefined) {
						console.log('ALL Type PPAP Phase :' + respOne.phase);
						this.phase = respOne.ppapPhase;
						this.plan = sessionStorage.getItem('ppapPlan');
						this.setPhasePlanDisplay(this.plan, this.phase);
						this.ppapOption.name = sessionStorage.getItem('ppapOption');
						console.log('this.ppapOption : ' + this.ppapOption);
						if (this.siteOption !== 'ALL') {
							this.siteCodes = [];
							this.siteCodes.push(this.siteOption);
							this.epswHomepageService.getData(this.siteCodes, this.phase, this.plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
								this.cmmsPartsPPap = resp;
								console.log(resp);
								console.log(this.cmmsPartsPPap);
								a1:for (const data of this.cmmsPartsPPap) {
									if (data.status === 'PENDING') {
										if (this.phase === 'Phase0' && data.cdRatrVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase1' && data.cdQualVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase2' && data.cdProdVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase3' && data.cdCapVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else {
											this.checkedStatus = false;
										}
									} else {
										this.checkedStatus = false;
									}
									this.checkStatus = data.status;
								}
								console.log('getData : ' + resp);
							});
						} else {
							console.log('this.ppapOption : ' + this.ppapOption);
							this.epswHomepageService.getData(this.siteCodeList, this.phase,
								this.plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
								this.cmmsPartsPPap = resp;
								console.log('getData : ' + resp);
							});
						}
					}
				} else if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
					|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser' || this.userRole ==='ePSW View Only') {
					this.checked1 = true;
					this.checked2 = false;
					this.showProgramValues = true;
					this.showSiteValues = false;
					console.log('programCodeCheck', this.programData);
					this.programCodeList = [];
					this.programCodeList.push(sessionStorage.getItem('programCode'));
					// this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodeList, this.siteList).subscribe(siteCodes => {
					// 	this.programCodeSiteCodes.push(siteCodes);

						this.phase = respOne.ppapPhase;
						this.plan = sessionStorage.getItem('ppapPlan');
						this.setPhasePlanDisplay(this.plan, this.phase);
						this.ppapOption.name = sessionStorage.getItem('ppapOption');
						if (this.ppapPhase.code === 'ALL') {
							console.log('ALL Type PPAP Phase');
							// this.getAllPPAPPhase(this.siteOption);
							// Adding All Option in PPAP Timing
							if (this.programCodes !== 'ALL') {
								this.programCodeList = [];
								this.programCodeList.push(sessionStorage.getItem('programCode'));
								// this.getBeyondSixPPAPPhase(this.siteCodes);
								this.getAllPrgmPPAPTiming(this.programCodeList);
							} else {
								this.getAllPrgmPPAPTiming(this.programCodeList);
							}
						}
						if (this.ppapPhase.code === 'PBS') {
							console.log('Beyond Six Month Type PPAP Phase');
							if (this.programCodes !== 'ALL') {
								this.programCodeList = [];
								this.programCodeList.push(sessionStorage.getItem('programCode'));
								this.getPrgmBeyondSixPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							} else {
								this.getPrgmBeyondSixPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							}
						}
						if (this.ppapPhase.code === 'PNS') {
							console.log('Next Six Month Type PPAP Phase');
							if (this.programCodes !== 'ALL') {
								this.programCodeList = [];
								this.programCodeList.push(sessionStorage.getItem('programCode'));
								this.getPrgmNextSixPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							} else {
								this.getPrgmNextSixPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							}
						}
						if (this.ppapPhase.code === 'PTU') {
							console.log('Parts Untimed Type PPAP Phase');
							if (this.programCodes !== 'ALL') {
								this.programCodeList = [];
								this.programCodeList.push(sessionStorage.getItem('programCode'));
								this.getPrgmPartsUntimedPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							} else {
								this.getPrgmPartsUntimedPPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							}
						}
						if (this.ppapPhase.code === 'PPD') {
							console.log('Past Due Type PPAP Phase');
							if (this.programCodes !== 'ALL') {
								this.programCodeList = [];
								this.programCodeList.push(sessionStorage.getItem('programCode'));
								this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							} else {
								this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
							}
						}
						if (this.programCodeList !== null && this.programCodeList !== undefined) {
							this.epswHomepageService.getProgramData(this.programCodeList[0], this.phase, this.plan,
								'ALL', 'ALL', this.priorityType.code, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
								if (resp.signedUrl) {

									this.http.get(resp.downloadUrl, {responseType: 'blob'})
										.subscribe(blob => {
											blob.text().then(text => {
												const jsonData = JSON.parse(text);
												this.cmmsPartsPPap = jsonData;
												this.digitalPpapPackageService.deleteFile(resp.fileName).subscribe(res => {

												});
												if (this.plan === 'PPAP AWAITING MY APPROVAL') {
													this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
														&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
													this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
												}
											});
										});
								}else {
									this.cmmsPartsPPap = resp.list;
									if (this.plan === 'PPAP AWAITING MY APPROVAL') {
										this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
											&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
										this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
									}
								}
							});
						}

				}else if (this.userRole === 'STASiteEngineer' || this.userRole === 'PDEngineer' ||
					this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
					this.userRole === 'APQPSiteSTAEngineer'){
					this.checked1 = true;
					this.checked2 = false;
					this.showProgramValues = true;
					this.showSiteValues = false;
					console.log('programCodeCheck',this.programData);
					this.programCodeList = [];
					this.programCodeList.push(sessionStorage.getItem('programCode'));
					this.phase = respOne.ppapPhase;
					this.plan = sessionStorage.getItem('ppapPlan');
					this.setPhasePlanDisplay(this.plan, this.phase);
					this.ppapOption.name = sessionStorage.getItem('ppapOption');
					if (this.ppapPhase.code === 'ALL') {
						console.log('ALL Type PPAP Phase');
						// this.getAllPPAPPhase(this.siteOption);
						// Adding All Option in PPAP Timing
						if (this.programCodes !== 'ALL') {
							this.programCodeList = [];
							this.programCodeList.push(sessionStorage.getItem('programCode'));
							// this.getBeyondSixPPAPPhase(this.siteCodes);
							this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodeList, this.siteList).subscribe( value => {
								this.siteValues = value;
								console.log('4032',this.siteValues);
							});
							this.getAllPrgmSitePPAPTiming(this.programCodeList,this.siteValues);
						} else {
							this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodeList, this.siteList).subscribe( value => {
								this.siteValues = value;
								console.log('4032',this.siteValues);
							});
							this.getAllPrgmSitePPAPTiming(this.programCodeList,this.siteValues);
						}
					}
					if (this.ppapPhase.code === 'PBS') {
						console.log('Beyond Six Month Type PPAP Phase');
						if (this.programCodes !== 'ALL') {
							this.programCodeList = [];
							this.programCodeList.push(sessionStorage.getItem('programCode'));
							this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
						} else {
							this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
						}
					}
					if (this.ppapPhase.code === 'PNS') {
						console.log('Next Six Month Type PPAP Phase');
						if (this.programCodes !== 'ALL') {
							this.programCodeList = [];
							this.programCodeList.push(sessionStorage.getItem('programCode'));
							this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
						} else {
							this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
						}
					}
					if (this.ppapPhase.code === 'PTU') {
						console.log('Parts Untimed Type PPAP Phase');
						if (this.programCodes !== 'ALL') {
							this.programCodeList = [];
							this.programCodeList.push(sessionStorage.getItem('programCode'));
							this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
						} else {
							this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
						}
					}
					if (this.ppapPhase.code === 'PPD') {
						console.log('Past Due Type PPAP Phase');
						if (this.programCodes !== 'ALL') {
							this.programCodeList = [];
							this.programCodeList.push(sessionStorage.getItem('programCode'));
							this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
						} else {
							this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
						}
					}
					if (this.programCodeList !== null && this.programCodeList !== undefined) {
						this.epswHomepageService.getProgramSiteData(this.programCodeList, this.phase, this.plan, this.ppapPhase.code, this.siteList, this.priorityType.code).subscribe(resp => {
							this.cmmsPartsPPap = resp;
							if (this.plan === 'PPAP AWAITING MY APPROVAL') {
								this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
									&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
								this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
							}
						});
					}
				} else {
					this.checked2 = true;
					this.checked1 = false;
					this.showProgramValues = false;
					this.showSiteValues = true;
					if (sessionStorage.getItem('siteOption') === 'ALL') {
						this.siteOption = sessionStorage.getItem('siteOption');
					} else {
						this.siteOption = respOne.siteCode;
					}
					// this.ppapCategoryOption = [{label: sessionStorage.getItem('ppapOption'),
					// 	value: {id: 1, name: sessionStorage.getItem('ppapOption'), code: 'APPAP'}}];
					// this.ppapOption = {name: sessionStorage.getItem('ppapOption'), code: sessionStorage.getItem('ppapOptionCode')};
					this.ppapOption = {name: sessionStorage.getItem('ppapOption'), code: sessionStorage.getItem('ppapOptionCode')};
					//alert('1'+this.ppapOption.name);
					this.getNoOfProgram(this.siteOption);
					this.getNoOfCommodities(this.siteOption);
					this.getSupplierName(this.siteOption);
					this.getTreeMapValues(this.siteOption);
					// Adding All Option in PPAP Timing
					respOne.ppapPhaseCode = sessionStorage.getItem('ppapTiming');
					if (respOne.ppapPhaseCode === 'PNS') {
						this.ppapPhase = {name: 'Next 6 Month', code: 'PNS'};
					}
					if (respOne.ppapPhaseCode === 'PBS') {
						this.ppapPhase = {name: 'Beyond Six Month', code: 'PBS'};
					}
					if (respOne.ppapPhaseCode === 'PTU') {
						this.ppapPhase = {name: 'Parts Untimed', code: 'PTU'};
					}
					if (respOne.ppapPhaseCode === 'PPD') {
						this.ppapPhase = {name: 'Past Due', code: 'PPD'};
					}
					// Adding All Option in PPAP Timing
					if (respOne.ppapPhaseCode === 'ALL') {
						this.ppapPhase = {name: 'All', code: 'ALL'};
					}
					if (this.siteOption != null && this.siteOption !== undefined) {
						if (this.ppapPhase.code === 'ALL') {
							console.log('ALL Type PPAP Phase');
							// this.getAllPPAPPhase(this.siteOption);
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getAllPPAPTiming(this.siteCodes);
							}
						}
						if (this.ppapPhase.code === 'PBS') {
							console.log('Beyond Six Month Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getBeyondSixPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getBeyondSixPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PNS') {
							console.log('Next Six Month Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getNextSixPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getNextSixPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PTU') {
							console.log('Parts Untimed Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getPartsUntimedPPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getPartsUntimedPPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
						if (this.ppapPhase.code === 'PPD') {
							console.log('Past Due Type PPAP Phase');
							if (this.siteOption !== 'ALL') {
								this.siteCodes = [];
								this.siteCodes.push(this.siteOption);
								this.getPastDuePPAPPhase(this.siteCodes,this.priorityType.code);
							} else {
								this.getPastDuePPAPPhase(this.siteCodeList,this.priorityType.code);
							}
						}
					}
					if (this.siteOption != null && this.siteOption !== undefined) {
						console.log('ALL Type PPAP Phase :' + respOne.phase);
						this.phase = respOne.ppapPhase;
						this.plan = sessionStorage.getItem('ppapPlan');
						this.setPhasePlanDisplay(this.plan, this.phase);
						this.ppapOption.name = sessionStorage.getItem('ppapOption');
						console.log('this.ppapOption : ' + this.ppapOption);
						if (this.siteOption !== 'ALL') {
							this.siteCodes = [];
							this.siteCodes.push(this.siteOption);
							this.epswHomepageService.getData(this.siteCodes, this.phase, this.plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
								this.cmmsPartsPPap = resp;
								console.log(resp);
								console.log(this.cmmsPartsPPap);
								a1:for (const data of this.cmmsPartsPPap) {
									if (data.status === 'PENDING') {
										if (this.phase === 'Phase0' && data.cdRatrVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase1' && data.cdQualVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase2' && data.cdProdVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else if (this.phase === 'Phase3' && data.cdCapVerif !== '') {
											this.checkedStatus = true;
											break a1;
										} else {
											this.checkedStatus = false;
										}
									} else {
										this.checkedStatus = false;
									}
									this.checkStatus = data.status;
								}
								console.log('getData : ' + resp);
							});
						} else {
							console.log('this.ppapOption : ' + this.ppapOption);
							this.epswHomepageService.getData(this.siteCodeList, this.phase,
								this.plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
								this.cmmsPartsPPap = resp;
								console.log('getData : ' + resp);
							});
						}
					}
				}
			});
			if(sessionStorage.getItem('buttonNumber') === '1'){
				this.button1  = true; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '2'){
				this.button1  = false; this.button2  = true; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '3'){
				this.button1  = false; this.button2  = false; this.button3  = true; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '4'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = true;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '5'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = true; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '6'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = true; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '7'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = true; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '8'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = true;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '9'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = true; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '10'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = true; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '11'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = true; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '12'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = true;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '13'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = true; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '14'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = true; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '15'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = true; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '16'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = true;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '17'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = true; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '18'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = true; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '19'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = true; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '20'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = true;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '21'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = true; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '22'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = true; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '23'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = true; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '24'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = true;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '25'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = true; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '26'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = true; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '27'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = true; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '28'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = true;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '29'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = true; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '30'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = true; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '31'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = true; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '32'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = true;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '33'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = true; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}
			else if (sessionStorage.getItem('buttonNumber') === '34'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = true; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '35'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = true; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			}else if (sessionStorage.getItem('buttonNumber') === '36'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = true;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '37'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = true; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '38'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = true; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '39'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = true; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '40'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = true;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '41'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = true; this.button42 = false; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '42'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = true; this.button43 = false; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '43'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = true; this.button44 = false;
			} else if (sessionStorage.getItem('buttonNumber') === '44'){
				this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
				this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
				this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
				this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
				this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
				this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
				this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
				this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
				this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
				this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
				this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = true;
			}
		}
	}

	ngOnInit(): void {
		console.log('This is from Dev Branch');
		this.getSiteCodeValues();
		if(this.pswAccessControl.get('programEnabled')) {
			this.getProgramCodeValues();
		}
		this.ppapLevelOption = {name: '', code: ''};
		this.ppapPackageIdentifierSave = new PpapPackageIdentifier(this.ppapPackageIdentifierSave);
		this.updateHighChart('No Data',0,0,0,0);
		this.cols = [
			{field: 'pgm', header: 'PGM'},
			{field: 'prefix', header: 'PREFIX'},
			{field: 'suffix', header: 'BASE'},
			{field: 'base', header: 'SUFFIX'},
			{field: 'partdescription', header: 'PART DESCRIPTION'},
			{field: 'rardt', header: 'RAR DT'},
			{field: 'qualdt', header: 'QUAL DT'},
			{field: 'proddt', header: 'PROD DT'},
			{field: 'capdt', header: 'CAPDT'},
			{field: 'status', header: 'STATUS'},
			{field: 'level', header: 'PPAP LEVEL'}
		];

		//table
		this.ppapTabDetail = [
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122347-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'NEEDS CLARIFICATION',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			},
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'InProgress',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122346-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'InProgress',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Annual Re-PPAP',
				pgm: '3694',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122347-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'AWAITING APPROVAL',
				level: 'Level 3'
			},
			{
				ppapcategory: 'All PPAP',
				pgm: '3691',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122348-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'AWAITING APPROVAL',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Lunch / Running Change PPAP',
				pgm: '3692',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122349-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			},
			{
				ppapcategory: 'Supplier Requested Change',
				pgm: '3693',
				prefix: 'AB39-',
				base: 'AE',
				suffix: '2122345-',
				partdescription: 'HGA ASY FRT DT',
				changeintiming: '  ',
				rardt: '08-Oct-18',
				rcheckout: '  ',
				qualdt: '08-Oct-18',
				qcheckout: '  ',
				proddt: '08-Oct-18',
				pcheckout: '  ',
				capdt: '08-Oct-18',
				status: 'PENDING',
				level: 'Level 3'
			}
		];
		// this.ppapPhase = {name: 'Next 6 Month', code: 'PNS'};
		// Adding All Option in PPAP Timing
		this.ppapPhase = {name: 'All', code: 'ALL'};
		this.priorityType = {name: 'All', code: 'ALL'};

		// this.siteOption = {name: 'ALL', value: 'ALL'};
		// this.checkAndShowRejectSuccessMsg();
		// this.checkAndShowApproveSuccessMsg();
		// this.checkAndShowInterimSuccessMsg();
		// this.checkAndShowNeedsClarificationSuccessMsg();
	}

	checkAndShowRejectSuccessMsg() {
		let success = sessionStorage.getItem('rejectMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('rejectMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowApproveSuccessMsg() {
		let success = sessionStorage.getItem('approveMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('approveMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowInterimSuccessMsg() {
		let success = sessionStorage.getItem('interimAcceptedMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('interimAcceptedMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	checkAndShowNeedsClarificationSuccessMsg() {
		let success = sessionStorage.getItem('needsClarificationMessage');
		if (success) {
			success = JSON.parse(success);
			this.messageService.add({severity: 'success', summary: '', detail: success['message']});
			sessionStorage.removeItem('needsClarificationMessage');
			setTimeout(() => {
				console.log('cleared');
				this.displayMessage = [];
			}, 2000);
		}
	}

	onChange() {
		this.selectedHeading = true;
	}

	onSelect() {
		// this.epswHomepageService.selectData().subscribe(respOne => {
		// });
	}

	getSiteCodeValues() {
		this.site = [];
		let iterator, siteCodeFromAPS;
		siteCodeFromAPS = JSON.parse(this.apsAttributes).siteCodes;
		console.log('sitevalues',siteCodeFromAPS);
		if (siteCodeFromAPS !== null && siteCodeFromAPS !== undefined) {
			if (siteCodeFromAPS.length > 0) {
				//Adding ALL to sitecode dr
				// Removed ALL for Supplier, Superuser && epsw admin
				if (!this.pswAccessControl.get('isSupplier') && this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin' && this.userRole !== 'Buyer'
					&& this.userRole !== 'STASrProgramManager' && this.userRole !== 'STAProgramEngineer' && this.userRole !== 'STAProgramManager'
					&& this.userRole !== 'MPLAnalyst' && this.userRole !== 'ePSW View Only') {
					this.site.push({label: 'ALL', value: 'ALL'});
					// For ALL as Default option
					// if(this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin'){
					this.siteOption = 'ALL';
					console.log(sessionStorage.getItem('siteOption'));
					if(sessionStorage.getItem('siteOption') !== 'undefined' && sessionStorage.getItem('siteOption') !== 'null'
						&& sessionStorage.getItem('siteOption') !== undefined && sessionStorage.getItem('siteOption') !== null
						&& sessionStorage.getItem('siteOption') !== 'ALL' && sessionStorage.getItem('siteOption') !== ''){
						this.siteOption = sessionStorage.getItem('siteOption');
					}
					sessionStorage.setItem('siteOption',this.siteOption);
					// }
				}
				for (iterator = 0; iterator < siteCodeFromAPS.length; iterator++) {
					this.site.push({label: siteCodeFromAPS[iterator], value: siteCodeFromAPS[iterator]});
					this.siteList.push(siteCodeFromAPS[iterator]);
				}
				console.log(this.siteList);
			}
		}
		// Adding sitecodes to sitecodeList
		if (this.site.length > 0) {
			for (const siteCode of this.site) {
				if (siteCode.value !== 'ALL') {
					this.siteCodeList.push(siteCode.value);
				}
			}
		}
		// For ALL as Default option
		if (siteCodeFromAPS.length > 0) {
			if (!this.pswAccessControl.get('isSupplier') && this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin' && this.userRole !== 'STAProgramEngineer'
				&& this.userRole !== 'STAProgramManager' && this.userRole !== 'STASrProgramManager' && this.userRole !== 'Buyer'
				&& this.userRole !== 'MPLAnalyst' && this.userRole !== 'ePSW View Only') {
				// Removed ALL for Supplier, Superuser && epsw admin
				// if(this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin') {
				if (sessionStorage.getItem('viewBy') === 'site') {
					this.noOfPrgCom();
				}
				// }
			}
		}
	}

	getNoOfProgram(siteCode: any) {
		if(siteCode !== 'ALL'){
			this.siteCodes = [];
			this.siteCodes.push(siteCode);
			this.epswHomepageService.getNoOfProgram(this.siteCodes).subscribe(resp => {
				console.log('Response for NoOfProgram : ' + resp);
				this.noOfPrg = resp;
			});
		} else{
			this.epswHomepageService.getNoOfProgram(this.siteCodeList).subscribe(resp => {
				console.log('Response for NoOfProgram : ' + resp);
				this.noOfPrg = resp;
			});
		}
	}

	getNoOfCommodities(siteCode: any) {
		if(siteCode !== 'ALL') {
			this.siteCodes = [];
			this.siteCodes.push(siteCode);
			this.epswHomepageService.getNoOfCommodities(this.siteCodes).subscribe(resp => {
				console.log('Response for NoOfCommodities : ' + resp);
				this.commodities = resp;
			});
		} else {
			this.epswHomepageService.getNoOfCommodities(this.siteCodeList).subscribe(resp => {
				console.log('Response for NoOfCommodities : ' + resp);
				this.commodities = resp;
			});
		}
	}

	noOfPrgCom() {
		this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
		this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
		this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
		this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
		this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
		this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
		this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
		this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
		this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
		this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
		this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		console.log(this.siteOption);
		this.cmmsPartsPPap = [];
		this.getNoOfProgram(this.siteOption);
		this.getNoOfCommodities(this.siteOption);
		this.getSupplierName(this.siteOption);
		this.getTreeMapValues(this.siteOption);
		this.resetPhasePlanDisplay();
		console.log('Past Due before Tree Map' + this.pastDue);
		//Default PPAP PLAN is changed to Next 6 Months
		// this.ppapPhase = {name: 'Next 6 Month', code: 'PNS'};
		// Adding All Option in PPAP Timing
		this.ppapPhase = {name: 'All', code: 'ALL'};
		this.priorityType = {name: 'All', code: 'ALL'};
		this.selectedPriorityType = this.priorityType.code;
		//const previousSelectedPpapCategory = sessionStorage.getItem('ppapOptionCode');
		// if(previousSelectedPpapCategory!==this.ppapOption.code) {
		// 	this.ppapOption = {name: 'All', code: 'ALLPPAP'};
		// }
		// this.ppapOptionCode= this.ppapOption.code;
		// sessionStorage.setItem('ppapOptionCode',this.ppapOptionCode);
		//
		sessionStorage.getItem('')
		if(this.siteOption !== 'ALL'){
			this.siteCodes = [];
			this.siteCodes.push(this.siteOption);
			sessionStorage.setItem('siteOption',this.siteOption);
			// this.getNextSixPPAPPhase(this.siteCodes);
			// Adding All Option in PPAP Timing
			this.getAllPPAPTiming(this.siteCodes);
		} else {
			sessionStorage.setItem('siteOption',this.siteOption);
			this.getAllPPAPTiming(this.siteCodeList);
		}
	}

	getAllPPAPPhase(siteCode : any) {
		this.epswHomepageService.getAllPPAPPhase(siteCode, this.ppapOption.name).subscribe(resp => {
			console.log('Response for AllPPAPPhase : '+resp);
			this.allPPAPPhase=resp;
			console.log('alls : '+ resp.phase0PlanSubmission);
			console.log('allPPAPPhase : '+ this.allPPAPPhase['phaseOPlanSubmission']);
			this.allPPAPPhase = resp;
		});
	}

	getBeyondSixPPAPPhase(siteCode : any, priorityType: string) {
		this.epswHomepageService.getBeyondSixPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),priorityType).subscribe(resp => {
			console.log('Response for BeyondSixPPAPPhase : '+resp);
			console.log('JSON Response for BeyondSixPPAPPhase : '+JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getPastDuePPAPPhase(siteCode : any, priorityType: string) {
		this.epswHomepageService.getPastDuePPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),priorityType).subscribe(resp => {
			console.log('Response for PastDuePPAPPhase : '+resp);
			console.log('JSON Response for PastDuePPAPPhase : '+JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getNextSixPPAPPhase(siteCode : any, priorityType: string) {
		this.epswHomepageService.getNextSixPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),priorityType).subscribe(resp => {
			console.log('Response for NextSixPPAPPhase : '+resp);
			this.allPPAPPhase = resp;
		});
	}

	getPartsUntimedPPAPPhase(siteCode : any, priorityType: string) {
		this.epswHomepageService.getPartsUntimedPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),priorityType).subscribe(resp => {
			console.log('Response for PartsUntimedPPAPPhase : '+resp);
			this.allPPAPPhase = resp;
		});
	}

	getTreeMapValues(siteCode: any) {
		if(siteCode !== 'ALL') {
			this.siteCodes = [];
			this.siteCodes.push(siteCode);
	// tslint:disable-next-line:max-line-length
			this.epswHomepageService.getBeyondSixMonthValues(this.siteCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
				console.log('Response for Beyond Six Month : ' + resp);
				this.beyondSixMonths = resp;
				// tslint:disable-next-line:max-line-length
				this.epswHomepageService.getPastDueValues(this.siteCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(pastDueResp => {
					console.log('Response for Past Due : ' + pastDueResp);
					this.pastDue = pastDueResp;
					// tslint:disable-next-line:max-line-length
					this.epswHomepageService.getNextSixMonthValues(this.siteCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(nextSixMonthResp => {
						console.log('Response for Next Six Months : ' + nextSixMonthResp);
						this.nextSixMonths = nextSixMonthResp;
						// tslint:disable-next-line:max-line-length
						this.epswHomepageService.getPartsUntimedValues(this.siteCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(vppUntimedResp => {
							console.log('Response for VPP Untimed : ' + vppUntimedResp);
							this.vppUntimed = vppUntimedResp;
							this.updateHighChart('yes', this.beyondSixMonths, this.nextSixMonths, this.vppUntimed, this.pastDue);
						});
					});
				});
			});
		} else {
			// tslint:disable-next-line:max-line-length
			this.epswHomepageService.getBeyondSixMonthValues(this.siteCodeList, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
				console.log('Response for Beyond Six Month : ' + resp);
				this.beyondSixMonths = resp;
				this.epswHomepageService.getPastDueValues(this.siteCodeList, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(pastDueResp => {
					console.log('Response for Past Due : ' + pastDueResp);
					this.pastDue = pastDueResp;
					this.epswHomepageService.getNextSixMonthValues(this.siteCodeList, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(nextSixMonthResp => {
						console.log('Response for Next Six Months : ' + nextSixMonthResp);
						this.nextSixMonths = nextSixMonthResp;
						this.epswHomepageService.getPartsUntimedValues(this.siteCodeList, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(vppUntimedResp => {
							console.log('Response for VPP Untimed : ' + vppUntimedResp);
							this.vppUntimed = vppUntimedResp;
							this.updateHighChart('yes', this.beyondSixMonths, this.nextSixMonths, this.vppUntimed, this.pastDue);
						});
					});
				});
			});
		}
	}

	selectPPAPPlan() {
		this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
		this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
		this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
		this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
		this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
		this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
		this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
		this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
		this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
		this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
		this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		console.log(this.siteOption);
		console.log(this.ppapPhase.code);
		this.selectedPriorityType = this.priorityType.code;
		this.resetPhasePlanDisplay();
		this.cmmsPartsPPap = [];
		if( this.siteOption !== null &&  this.siteOption!== undefined){
			if(this.ppapPhase.code === 'ALL' && this.priorityType.code === 'ALL'){
				console.log('ALL Type PPAP Phase');
				// this.getAllPPAPPhase(this.siteOption);
				// Adding All Option in PPAP Timing
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					// this.getBeyondSixPPAPPhase(this.siteCodes);
					this.getAllPPAPTiming(this.siteCodes);
				} else {
					this.getAllPPAPTiming(this.siteCodeList);
				}
			}
			if(this.ppapPhase.code === 'ALL' && this.priorityType.code === 'NPR'){
				console.log('ALL Type PPAP Phase');
				// this.getAllPPAPPhase(this.siteOption);
				// Adding All Option in PPAP Timing
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					// this.getBeyondSixPPAPPhase(this.siteCodes);
					this.getAllPPAPTiming(this.siteCodes);
				} else {
					this.getAllPPAPTiming(this.siteCodeList);
				}
			}
			if(this.ppapPhase.code === 'ALL' && this.priorityType.code === 'PR'){
				console.log('ALL Type PPAP Phase');
				// this.getAllPPAPPhase(this.siteOption);
				// Adding All Option in PPAP Timing
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					// this.getBeyondSixPPAPPhase(this.siteCodes);
					this.getAllPPAPTiming(this.siteCodes);
				} else {
					this.getAllPPAPTiming(this.siteCodeList);
				}
			}
			if(this.ppapPhase.code === 'PBS' && this.priorityType.code === 'ALL'){
				console.log('Beyond Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getBeyondSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getBeyondSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PBS' && this.priorityType.code === 'PR'){
				console.log('Beyond Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getBeyondSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getBeyondSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PBS' && this.priorityType.code === 'NPR'){
				console.log('Beyond Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getBeyondSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getBeyondSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PNS' && this.priorityType.code === 'ALL'){
				console.log('Next Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getNextSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getNextSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PNS' && this.priorityType.code === 'PR'){
				console.log('Next Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getNextSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getNextSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PNS' && this.priorityType.code === 'NPR'){
				console.log('Next Six Month Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getNextSixPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getNextSixPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PTU' && this.priorityType.code === 'ALL'){
				console.log('Parts Untimed Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getPartsUntimedPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getPartsUntimedPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PTU' && this.priorityType.code === 'PR'){
				console.log('Parts Untimed Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getPartsUntimedPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getPartsUntimedPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PTU' && this.priorityType.code === 'NPR'){
				console.log('Parts Untimed Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getPartsUntimedPPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getPartsUntimedPPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
			if(this.ppapPhase.code === 'PPD'){
				console.log('Past Due Type PPAP Phase');
				if(this.siteOption !== 'ALL') {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.getPastDuePPAPPhase(this.siteCodes,this.priorityType.code);
				} else {
					this.getPastDuePPAPPhase(this.siteCodeList,this.priorityType.code);
				}
			}
		} else if (this.programCodes !== undefined && this.programCodes !== null){
			if (this.userRole === 'STASiteEngineer' || this.userRole === 'PDEngineer' ||
				this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
				this.userRole === 'APQPSiteSTAEngineer') {
				this.epswHomepageService.getSiteCodesFromProgram(sessionStorage.getItem('programDropdown'),this.siteList).subscribe( value => {
					this.siteValues = value;
					console.log('4032', this.siteValues);
				});
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'ALL') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					} else {
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					}
				}
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'PR') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					} else {
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					}
				}
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'NPR') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					} else {
						this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
					}
				}
				if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'ALL') {
					console.log('Beyond Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'PR') {
					console.log('Beyond Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'NPR') {
					console.log('Beyond Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteBeyondSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'ALL') {
					console.log('Next Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'NPR') {
					console.log('Next Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'PR') {
					console.log('Next Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSiteNextSixPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'ALL') {
					console.log('Parts Untimed Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'NPR') {
					console.log('Parts Untimed Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'PR') {
					console.log('Parts Untimed Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePartsUntimedPPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'ALL') {
					console.log('Past Due Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'NPR') {
					console.log('Past Due Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'PR') {
					console.log('Past Due Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					} else {
						this.getPrgmSitePastDuePPAPPhase(this.programCodeList);
					}
				}
			  } else {
                // this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodes,this.siteList).subscribe(siteCodes =>{
				// 	this.programCodeSiteCodes.push(siteCodes);
				// });
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'ALL') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmPPAPTiming(this.programCodeList);
					} else {
						this.getAllPrgmPPAPTiming(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'NPR') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmPPAPTiming(this.programCodeList);
					} else {
						this.getAllPrgmPPAPTiming(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'ALL' && this.priorityType.code === 'PR') {
					console.log('ALL Type PPAP Phase');
					// this.getAllPPAPPhase(this.siteOption);
					// Adding All Option in PPAP Timing
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						// this.getBeyondSixPPAPPhase(this.siteCodes);
						this.getAllPrgmPPAPTiming(this.programCodeList);
					} else {
						this.getAllPrgmPPAPTiming(this.programCodeList);
					}
				}
				if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'ALL') {
					console.log('Beyond Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					} else {
						this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					}
				}
			if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'PR') {
				console.log('Beyond Six Month Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
			if (this.ppapPhase.code === 'PBS' && this.priorityType.code === 'NPR') {
				console.log('Beyond Six Month Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmBeyondSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
				if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'ALL') {
					console.log('Next Six Month Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					} else {
						this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					}
				}
			if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'NPR') {
				console.log('Next Six Month Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
			if (this.ppapPhase.code === 'PNS' && this.priorityType.code === 'PR') {
				console.log('Next Six Month Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmNextSixPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
				if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'ALL') {
					console.log('Parts Untimed Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					} else {
						this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
					}
				}
			if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'NPR') {
				console.log('Parts Untimed Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
			if (this.ppapPhase.code === 'PTU' && this.priorityType.code === 'PR') {
				console.log('Parts Untimed Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				} else {
					this.getPrgmPartsUntimedPPAPPhase(this.programCodeList,this.programCodeSiteCodes);
				}
			}
				if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'ALL') {
					console.log('Past Due Type PPAP Phase');
					if (this.programCodes !== 'ALL') {
						this.programCodeList = [];
						this.programCodeList.push(this.programCodes);
						this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
					} else {
						this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
					}
				}
			if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'NPR') {
				console.log('Past Due Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
				} else {
					this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
				}
			}
			if (this.ppapPhase.code === 'PPD' && this.priorityType.code === 'PR') {
				console.log('Past Due Type PPAP Phase');
				if (this.programCodes !== 'ALL') {
					this.programCodeList = [];
					this.programCodeList.push(this.programCodes);
					this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
				} else {
					this.getPrgmPastDuePPAPPhase(this.programCodeList, this.programCodeSiteCodes);
				}
			}
			}
		}
		else {
			this.checkSiteCode();
		}
	}

	checkSiteCode() {
		this.messageService.add({ severity:'info', summary: 'Info', detail: 'Please Select the Site Code'});
	}

	getData(phase, plan, buttonNumber: any) {
		if(buttonNumber === 1){
			this.button1  = true; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 2){
			this.button1  = false; this.button2  = true; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 3){
			this.button1  = false; this.button2  = false; this.button3  = true; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 4){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = true;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 5){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = true; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 6){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = true; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 7){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = true; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 8){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = true;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 9){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = true; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 10){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = true; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 11){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = true; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 12){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = true;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 13){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = true; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 14){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = true; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 15){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = true; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 16){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = true;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 17){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = true; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 18){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = true; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 19){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = true; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 20){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = true;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 21){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = true; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 22){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = true; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 23){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = true; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 24){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = true;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 25){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = true; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 26){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = true; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 27){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = true; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 28){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = true;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 29){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = true; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 30){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = true; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 31){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = true; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 32){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = true;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 33){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = true; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}
		else if (buttonNumber === 34){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = true; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 35){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = true; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		}else if (buttonNumber === 36){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = true;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 37){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = true; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 38){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = true; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 39){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = true; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 40){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = true;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 41){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = true; this.button42 = false; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 42){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = true; this.button43 = false; this.button44 = false;
		} else if (buttonNumber === 43){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = true; this.button44 = false;
		} else if (buttonNumber === 44){
			this.button1  = false; this.button2  = false; this.button3  = false; this.button4  = false;
			this.button5  = false; this.button6  = false; this.button7  = false; this.button8  = false;
			this.button9  = false; this.button10 = false; this.button11 = false; this.button12 = false;
			this.button13 = false; this.button14 = false; this.button15 = false; this.button16 = false;
			this.button17 = false; this.button18 = false; this.button19 = false; this.button20 = false;
			this.button21 = false; this.button22 = false; this.button23 = false; this.button24 = false;
			this.button25 = false; this.button26 = false; this.button27 = false; this.button28 = false;
			this.button29 = false; this.button30 = false; this.button31 = false; this.button32 = false;
			this.button33 = false; this.button34 = false; this.button35 = false; this.button36 = false;
			this.button37 = false; this.button38 = false; this.button39 = false; this.button40 = false;
			this.button41 = false; this.button42 = false; this.button43 = false; this.button44 = true;
		}
		sessionStorage.setItem('buttonNumber',buttonNumber);
		sessionStorage.setItem('ppapPlan',plan);
		this.ppapOptionSession= this.ppapOption.name;
		sessionStorage.setItem('ppapPhase', phase);
		sessionStorage.setItem('displayPpapPhase', phase);
		// sessionStorage.setItem('ppapPlan',plan);
		// Adding All Option in PPAP Timing
		sessionStorage.setItem('ppapTiming',this.ppapPhase.code);
		this.ppapOptionCode= this.ppapOption.code;
		sessionStorage.setItem('ppapOption',this.ppapOptionSession);
		sessionStorage.setItem('ppapOptionCode',this.ppapOptionCode);
		this.selectedPpapParts =[];
		if( this.siteOption != null &&  this.siteOption!== undefined) {
			console.log('ALL Type PPAP Phase :' +phase);
			this.phase = phase;
			this.plan = plan;
			this.setPhasePlanDisplay(plan, phase);
			if(this.siteOption !== 'ALL') {
				console.log('2423',this.checked1)
				if(this.programCodes !== undefined && this.programCodes !== null && this.checked1) {
					this.epswHomepageService.getProgramSiteData(this.programCodes, phase, plan, this.ppapPhase.code, this.siteValues,this.priorityType.code).subscribe(resp => {
						this.cmmsPartsPPap = resp;
						if (plan === 'PPAP AWAITING MY APPROVAL') {
							this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
								&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
							this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
						}
						const cmmsPartsPPap2 = [...new Set(this.cmmsPartsPPap.map(p => p.siteCode))];
						console.log('get Data distinct : ' + cmmsPartsPPap2);
						for (const item of cmmsPartsPPap2) {
							if(item !== null) {
								this.epswHomepageService.getPrgmSupplierName(item).subscribe(resp2 => {
									for(const item2 of this.cmmsPartsPPap) {
										if (item2.siteCode === item) {
											console.log('Response for Supplier Name : ' + resp2);
											if (resp2.simsahProdPpapLevel !== null) {
												item2.simsahProdPpapLevel = resp2.simsahProdPpapLevel;
											}
										}
									}
								});
							}
						}
					});
				} else {
					this.siteCodes = [];
					this.siteCodes.push(this.siteOption);
					this.epswHomepageService.getData(this.siteCodes, phase, plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
						this.cmmsPartsPPap = resp;
						console.log('2431',this.cmmsPartsPPap);
						if (plan === 'PPAP AWAITING MY APPROVAL') {
							this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
								&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
							this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
						}
						a1:for (const data of this.cmmsPartsPPap) {
							if (data.status === 'PENDING') {
								if (this.phase === 'Phase0' && data.cdRatrVerif !== '') {
									this.checkedStatus = true;
									break a1;
								} else if (this.phase === 'Phase1' && data.cdQualVerif !== '') {
									this.checkedStatus = true;
									break a1;
								} else if (this.phase === 'Phase2' && data.cdProdVerif !== '') {
									this.checkedStatus = true;
									break a1;
								} else if (this.phase === 'Phase3' && data.cdCapVerif !== '') {
									this.checkedStatus = true;
									break a1;
								} else {
									this.checkedStatus = false;
								}
							} else {
								this.checkedStatus = false;
							}
							this.checkStatus = data.status;
						}
						console.log('get Data : ' + resp);
					});
				}
			} else {
				if(this.programCodes !== undefined && this.programCodes !== null && this.checked1) {
					if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
						|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser' ||this.userRole ==='ePSW View Only') {
						if (this.programCodes !== undefined && this.programCodes !== null) {
							// this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodes, this.siteList).subscribe(siteCodes =>{
							// 	this.programCodeSiteCodes.push(siteCodes);
							// });

							this.epswHomepageService.getProgramData(this.programCodes, phase, plan, this.ppapPhase.code, this.ppapOption.name,  this.priorityType.code, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
								if (resp.signedUrl) {

									this.http.get(resp.downloadUrl, {responseType: 'blob'})
										.subscribe(blob => {
											blob.text().then(text => {
												const jsonData = JSON.parse(text);
												this.cmmsPartsPPap = jsonData;
												this.digitalPpapPackageService.deleteFile(resp.fileName).subscribe(res => {

												});
												if (plan === 'PPAP AWAITING MY APPROVAL') {
													this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
														&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
													this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
												}
											});
										});
								}else {

									this.cmmsPartsPPap = resp.list;
									this.setPhasePlanDisplay(plan, phase);
									if (plan === 'PPAP AWAITING MY APPROVAL') {
										this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
											&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
										this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
									}
								}
							});
						}
					} else {
						this.epswHomepageService.getProgramSiteData(this.programCodes, phase, plan, this.ppapPhase.code, this.siteValues, this.priorityType.code).subscribe(resp => {
							this.cmmsPartsPPap = resp;
							if (plan === 'PPAP AWAITING MY APPROVAL') {
								this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
									&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
								this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
							}
						});
					}
				} else {
					this.epswHomepageService.getData(this.siteCodeList, phase, plan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp => {
						this.cmmsPartsPPap = resp;
						if (plan === 'PPAP AWAITING MY APPROVAL') {
							this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
								&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
							this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
						}
						const cmmsPartsPPap2 = [...new Set(this.cmmsPartsPPap.map(p => p.siteCode))];
						console.log('get Data distinct : ' + cmmsPartsPPap2);
						console.log('get Data : ' + resp);
						for (const item of cmmsPartsPPap2) {
							this.epswHomepageService.getSupplierName(item).subscribe(resp2 => {
								console.log('Response for Supplier Name : ' + resp2);
								for(const item2 of this.cmmsPartsPPap) {
									if (item2.siteCode === item) {
										console.log('Response for Supplier Name  111 : ' + item2.siteCode);
									item2.simsahProdPpapLevel = resp2.simsahProdPpapLevel;
								}
								}
							});
						}
					});
				}
			}
		} else if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
			|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser'||this.userRole ==='ePSW View Only') {
			if(this.programCodes !== undefined && this.programCodes !== null) {
				// this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodes, this.siteList).subscribe(siteCodes =>{
				// 	this.programCodeSiteCodes.push(siteCodes);
				// });

				this.epswHomepageService.getProgramData(this.programCodes, phase, plan, this.ppapPhase.code, this.ppapOption.name,this.priorityType.code, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
					if (resp.signedUrl) {

						this.http.get(resp.downloadUrl, {responseType: 'blob'})
							.subscribe(blob => {
								blob.text().then(text => {
									const jsonData = JSON.parse(text);
									this.cmmsPartsPPap = jsonData;
									this.digitalPpapPackageService.deleteFile(resp.fileName).subscribe(res => {

									});
									this.setPhasePlanDisplay(plan, phase);

									if (plan === 'PPAP AWAITING MY APPROVAL') {
										this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
											&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
										this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
									}
								});
							});
					} else {
						this.cmmsPartsPPap = resp.list;
						this.setPhasePlanDisplay(plan, phase);
						if (plan === 'PPAP AWAITING MY APPROVAL') {
							this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
								&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
							this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
						}
					}

				});
			}
		} else {
			if(this.programCodes !== undefined && this.programCodes !== null) {
				this.epswHomepageService.getProgramSiteData(this.programCodes, phase, plan, this.ppapPhase.code, this.siteValues, this.priorityType.code).subscribe(resp => {
					this.cmmsPartsPPap = resp;
					if (plan === 'PPAP AWAITING MY APPROVAL') {
						this.cmmsPartsPPapFiltered = this.cmmsPartsPPap.filter(pdApprovalQueue => pdApprovalQueue.status === 'AWAITING APPROVAL'
							&& pdApprovalQueue.staPdStatus.includes(sessionStorage.getItem('userId').toUpperCase()));
						this.cmmsPartsPPap = this.cmmsPartsPPapFiltered;
					}
				});
			}
		}
	}


	getSaveData() {
		const lookup = this.ppapPackagePartsForPPAPLevel.reduce((ppapPackagePartsForPPAPLevel, ppapPackageParts) => {
			ppapPackagePartsForPPAPLevel[ppapPackageParts.ppapLevel] = ++ppapPackagePartsForPPAPLevel[ppapPackageParts.ppapLevel] || 0;
			return ppapPackagePartsForPPAPLevel;
		}, {});
		this.countOfPPAPLevel = this.ppapPackagePartsForPPAPLevel.
		filter(ppapPackagePartsForPPAPLevel => lookup[ppapPackagePartsForPPAPLevel.ppapLevel]);
		console.log('after call' + this.ppapPackageParts);
		if (this.selectedPpapParts.length > 1) {
			if (this.countOfPPAPLevel.length < 0) { //Same PPAP Level
				// if (this.countOfPPAPLevel.length >= 0) { //Different PPAP Level
				if (this.ppapLevelOption.name === '') {
					this.confirmationService.confirm({
						message: 'Parts selected contains more than one PPAP Level. Please select PPAP Level Override.',
						header: 'Confirmation',
						icon: 'fa fa-info-circle',
						accept: () => {
							document.getElementById('ppapLevelComp').focus();
						}
					});
				} else {
					this.saveIdentifierData(false);
				}
			} else {
				this.saveIdentifierData(true);
			}
		} else {
			this.saveIdentifierData(true);
		}
	}

	saveIdentifierData(flag){
		const baseList: any[]=[];
		let isSelectedBasePart = true;
		console.log(this.siteOption);
		this.ppapPackageIdentifierSave.siteCode = this.siteOption;
		this.ppapPackageIdentifierSave.ppapPhase = this.phase;
		this.ppapPackageIdentifierSave.ppapPhaseCode = this.ppapPhase.code;
		this.ppapPackageIdentifierSave.ppapPhasePlan = this.plan;
		// this.ppapPackageIdentifierSave.pdApprovalRequired = undefined;
		console.log('before call' + this.ppapPackageParts);
		this.ppapPackageParts =[];
		this.partsArray=[];
		console.log(this.ppapPhase.name !== 'Parts Untimed');
		console.log(this.ppapPhase.name);

		if (this.selectedPpapParts.length !== 0) {
			for (const currentRow of this.selectedPpapParts) {
				if(currentRow.status === 'PENDING' ) {
					if(this.phase ==='Phase0' && currentRow.cdRatrVerif !==  ''){
						this.partsArray.push(currentRow);
					} else if(this.phase ==='Phase1' && currentRow.cdQualVerif !==  ''){
						this.partsArray.push(currentRow);
					} else if(this.phase ==='Phase2' && currentRow.cdProdVerif !==  ''){
						this.partsArray.push(currentRow);
					} else if(this.phase ==='Phase3' && currentRow.cdCapVerif !==  ''){
						this.partsArray.push(currentRow);
					}
				}
			}
			const set = new Set(this.partsArray);
			this.partsArray = Array.from(set);
			this.selectedPpapParts=[];
			this.selectedPpapParts=this.partsArray;
			console.log(this.selectedPpapParts);
		}
		if (this.selectedPpapParts.length !== 0) {
			if(flag === true){
				for (const currentRow of this.selectedPpapParts) {
					if (this.ppapLevelOption.name !== this.simppapLevel && this.ppapLevelOption.name !== ''  ) {
						this.ppapPackageParts.push({
							capDt: new Date(currentRow.dtCapVerif),
							noPartBase: currentRow.noPartBase,
							noPartPrefix: currentRow.noPartPrefix,
							noPartSuffix: currentRow.noPartSuffix,
							ppapLevel: this.ppapLevelOption.name,
							ppapPackageIdentifierID: null,
							ppapPackagePartsEntityID: null,
							prodDt: new Date(currentRow.dtProdVerif),
							partDescription: currentRow.partDescription,
							qualDt: new Date(currentRow.dtQualVerif),
							rarDt: new Date(currentRow.dtRatrVerif),
							select: '',
							noLstNotice: currentRow.noLstNotice,
							dtLstNotice: null,
							status: 'IN PROGRESS',
							programCode: currentRow.programCode,
							nextAuditLayout: '',
							isFormCompleted: '',
							submittedEmailId: '',
							submittedCdsid: '',
							submittedBy: '',
							submittedOn: null,
							ppapStaStatus: '',
							ppapStaStatusBy: '',
							ppapStaStatusOn: null,
							ppapStaComments: '',
							ppapPdStatus: '',
							ppapPdStatusBy: '',
							ppapPdStatusOn: null,
							ppapPdComments: '',
							overallPpapStatus: '',
							pswStaStatus: '',
							pswStaStatusBy: '',
							pswStaUserId: '',
							pswStaStatusOn: null,
							pswStaComments: '',
							pswPdStatus: '',
							pswPdStatusBy: '',
							pswPdUserId: '',
							pswPdStatusOn: null,
							pswPdComments: '',
							overallPswStatus: '',
							partNumber:currentRow.noPartPrefix+'-'+currentRow.noPartBase+'-'+currentRow.noPartSuffix,
							phase1Phase2 : null,
							lastUpdatedBy: '',
							lastUpdatedOn: null,
							createdBy:'',
							createdOn:null,
							programName: currentRow.programName,
							naComments: '',
							familyPswId: null,
							familyPswOrgPartNumber: '',
							familyPswPoNumber: '',
							familyPswWeight: '',
							familyPswIMDSIDNo: '',
							familyPswOptional1: '',
							familyPswOptional2: '',
							familyPswOptional3: '',
							familyPswOptional4: '',
							familyPswOptional5: '',
							recallComments: null,
							recallDate: null,
							recallFlow: null,
							recallBy: null,
							staApprovalRequired: 'true',
							priorityStatus: currentRow.priorityStatus,
							cdSupplierShpFr: currentRow.cdSupplierShpFr,
							programDescription: currentRow.programDescription
						}); }
					else {
						this.ppapPackageParts.push({
							capDt: new Date(currentRow.dtCapVerif),
							noPartBase: currentRow.noPartBase,
							noPartPrefix: currentRow.noPartPrefix,
							noPartSuffix: currentRow.noPartSuffix,
							ppapLevel: this.simppapLevel,
							ppapPackageIdentifierID: null,
							ppapPackagePartsEntityID: null,
							prodDt: new Date(currentRow.dtProdVerif),
							partDescription: currentRow.partDescription,
							qualDt: new Date(currentRow.dtQualVerif),
							rarDt: new Date(currentRow.dtRatrVerif),
							select: '',
							noLstNotice: currentRow.noLstNotice,
							dtLstNotice: null,
							status: 'IN PROGRESS',
							programCode: currentRow.programCode,
							nextAuditLayout: '',
							isFormCompleted: '',
							submittedEmailId: '',
							submittedCdsid: '',
							submittedBy: '',
							submittedOn: null,
							ppapStaStatus: '',
							ppapStaStatusBy: '',
							ppapStaStatusOn: null,
							ppapStaComments: '',
							ppapPdStatus: '',
							ppapPdStatusBy: '',
							ppapPdStatusOn: null,
							ppapPdComments: '',
							overallPpapStatus: '',
							pswStaStatus: '',
							pswStaStatusBy: '',
							pswStaUserId: '',
							pswStaStatusOn: null,
							pswStaComments: '',
							pswPdStatus: '',
							pswPdStatusBy: '',
							pswPdUserId: '',
							pswPdStatusOn: null,
							pswPdComments: '',
							overallPswStatus: '',
							partNumber:currentRow.noPartPrefix+'-'+currentRow.noPartBase+'-'+currentRow.noPartSuffix,
							phase1Phase2 : null,
							lastUpdatedBy: '',
							lastUpdatedOn: null,
							createdBy:'',
							createdOn:null,
							programName: currentRow.programName,
							naComments: '',
							familyPswId: null,
							familyPswOrgPartNumber: '',
							familyPswPoNumber: '',
							familyPswWeight: '',
							familyPswIMDSIDNo: '',
							familyPswOptional1: '',
							familyPswOptional2: '',
							familyPswOptional3: '',
							familyPswOptional4: '',
							familyPswOptional5: '',
							recallComments: null,
							recallDate: null,
							recallFlow: null,
							recallBy: null,
							staApprovalRequired: 'true',
							priorityStatus: currentRow.priorityStatus,
							cdSupplierShpFr: currentRow.cdSupplierShpFr,
							programDescription: currentRow.programDescription
						});
					}
				}
			} else {
				for (const currentRow of this.selectedPpapParts) {
					this.ppapPackageParts.push({
						capDt: new Date(currentRow.dtCapVerif),
						noPartBase: currentRow.noPartBase,
						noPartPrefix: currentRow.noPartPrefix,
						noPartSuffix: currentRow.noPartSuffix,
						staApprovalRequired: currentRow.staApprovalRequired,
						priorityStatus: currentRow.priorityStatus,
						ppapLevel: this.ppapLevelOption.name,
						ppapPackageIdentifierID: null,
						ppapPackagePartsEntityID: null,
						prodDt: new Date(currentRow.dtProdVerif),
						partDescription: currentRow.partDescription,
						qualDt: new Date(currentRow.dtQualVerif),
						rarDt: new Date(currentRow.dtRatrVerif),
						select: '',
						noLstNotice: currentRow.noLstNotice,
						dtLstNotice: null,
						status: 'IN PROGRESS',
						programCode: currentRow.programCode,
						nextAuditLayout: '',
						isFormCompleted: '',
						submittedEmailId: '',
						submittedCdsid: '',
						submittedBy: '',
						submittedOn: null,
						ppapStaStatus: '',
						ppapStaStatusBy: '',
						ppapStaStatusOn: null,
						ppapStaComments: '',
						ppapPdStatus: '',
						ppapPdStatusBy: '',
						ppapPdStatusOn: null,
						ppapPdComments: '',
						overallPpapStatus: '',
						pswStaStatus: '',
						pswStaStatusBy: '',
						pswStaUserId: '',
						pswStaStatusOn: null,
						pswStaComments: '',
						pswPdStatus: '',
						pswPdStatusBy: '',
						pswPdUserId: '',
						pswPdStatusOn: null,
						pswPdComments: '',
						overallPswStatus: '',
						lastUpdatedBy: '',
						lastUpdatedOn: null,
						partNumber:currentRow.noPartPrefix+'-'+currentRow.noPartBase+'-'+currentRow.noPartSuffix,
						phase1Phase2 : null,
						createdBy:'',
						createdOn:null,
						programName: currentRow.programName,
						naComments: '',
						familyPswId: null,
						familyPswOrgPartNumber: '',
						familyPswPoNumber: '',
						familyPswWeight: '',
						familyPswIMDSIDNo: '',
						familyPswOptional1: '',
						familyPswOptional2: '',
						familyPswOptional3: '',
						familyPswOptional4: '',
						familyPswOptional5: '',
						recallComments: null,
						recallDate: null,
						recallFlow: null,
						recallBy: null,
						cdSupplierShpFr: currentRow.cdSupplierShpFr,
						programDescription: currentRow.programDescription
					});
				}
			}
			this.ppapPackageIdentifierSave.addOrUpdateParts=true;
			if (this.selectedPpapParts.length > 1) {
				for (const currentRow of this.selectedPpapParts) {
					baseList.push(currentRow.noPartBase);
				}
				if (baseList.includes('1508')) {
					for (const basePart of baseList) {
						if (basePart !== '1508') {
							isSelectedBasePart = false;
						}
					}
				}
				if (!isSelectedBasePart) {
					this.messageService.add({
						severity: 'info',
						summary: 'Info',
						detail: 'PPAP Package with Base Part ‘1508’ should not include any other Base Parts in it.',
						life: 5000
					});
				} else {
					this.ppapPackageIdentifierSave.ppapPackagePartsEntity = this.ppapPackageParts;
					//this.getPriorityStatus(this.ppapPackageParts, this.ppapPackageIdentifierSave);
						this.epswHomepageService.saveData(this.ppapPackageIdentifierSave).subscribe(resp => {
							console.log('Save Data: ' + resp);
							this.ppapPackageIdentifierSave = resp;
							this.chartPpapElementSection();
						});
				}
			} else {
				this.ppapPackageIdentifierSave.ppapPackagePartsEntity = this.ppapPackageParts;
				//this.getPriorityStatus(this.ppapPackageParts, this.ppapPackageIdentifierSave);
					this.epswHomepageService.saveData(this.ppapPackageIdentifierSave).subscribe(resp => {
						console.log('Save Data: ' + resp);
						this.ppapPackageIdentifierSave = resp;
						this.chartPpapElementSection();
					});
			}
			this.ppapPackageIdentifierSave.addOrUpdateParts=false;
		} else {
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package'
			});
		}
	}

	nextPage() {
		console.log(this.cmmsPartsPPap);
		if( this.cmmsPartsPPap != null &&  this.cmmsPartsPPap !== undefined){
			console.log(this.selectedPpapParts);
			let selectParts: any[] = [];
			if (this.selectedPpapParts.length !== 0) {
				for (const currentRow of this.selectedPpapParts) {
					if(currentRow.status === 'PENDING' ) {
						if(this.phase ==='Phase0' && currentRow.cdRatrVerif !==  ''){
							selectParts.push(currentRow);
						} else if(this.phase ==='Phase1' && currentRow.cdQualVerif !==  ''){
							selectParts.push(currentRow);
						} else if(this.phase ==='Phase2' && currentRow.cdProdVerif !==  ''){
							selectParts.push(currentRow);
						} else if(this.phase ==='Phase3' && currentRow.cdCapVerif !==  ''){
							selectParts.push(currentRow);
						}
					}
				}
				const set = new Set(selectParts);
				selectParts = Array.from(set);
				console.log(selectParts);
			}
			let selectedProgramCode: any[] = [];
			if(selectParts.length > 1) {
				for (let item of selectParts) {
					selectedProgramCode.push(item.programCode);
				}
			}
			console.log(selectedProgramCode);
			const isRunningChange = selectedProgramCode.includes('000001') || selectedProgramCode.includes('000021')
				|| selectedProgramCode.includes('000022')|| selectedProgramCode.includes('000023') || selectedProgramCode.includes('000024');
			console.log(isRunningChange);
			selectedProgramCode = selectedProgramCode.filter(selectedPCode => selectedPCode !== '000001');
			selectedProgramCode = selectedProgramCode.filter(selectedPCode => selectedPCode !== '000021');
			selectedProgramCode = selectedProgramCode.filter(selectedPCode => selectedPCode !== '000022');
			selectedProgramCode = selectedProgramCode.filter(selectedPCode => selectedPCode !== '000023');
			selectedProgramCode = selectedProgramCode.filter(selectedPCode => selectedPCode !== '000024');
			console.log(selectedProgramCode);
			if(isRunningChange === false) {
				this.getSaveData();
			} else if(isRunningChange === true && selectedProgramCode.length < 1){
				this.getSaveData();
			} else {
				this.messageService.add({ severity:'info', summary: 'Info', detail: 'Do not combine Forward Model and Running Change PPAP, submit them separately',life: 5000});
			}
		}  else {
			this.messageService.add({ severity:'info', summary: 'Info', detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package',life: 5000});
		}
	}

	private getSupplierName(siteCode) {
		if (siteCode !== 'ALL') {
			this.epswHomepageService.getSupplierName(siteCode).subscribe(resp => {
				console.log('Response for Supplier Name : ' + resp);
				this.supplier = null;
				if (resp.simsahSelectedSiteName != null) {
					this.supplier = resp.simsahSelectedSiteName;
				} else if (resp.simsraT020SiteFullN !== null &&
					resp.simsraT020SiteFullN !== '~!') {
					this.supplier = resp.simsraT020SiteFullN;
				}
				if (resp.simsahStaSelectedSiteStreetName != null) {
					this.supplier = this.supplier + ',' + resp.simsahStaSelectedSiteStreetName;
				} else if (resp.simsraT020SiteStreetN !== null &&
					resp.simsraT020SiteStreetN !== '~!') {
					this.supplier = this.supplier + ',' + resp.simsraT020SiteStreetN;
				}
				if (resp.simsahStaSelectedSiteCityName != null) {
					this.supplier = this.supplier + ',' + resp.simsahStaSelectedSiteCityName;
				} else if (resp.simsraT020SiteCityN !== null && resp.simsraT020SiteCityN !== '~!') {
					this.supplier = this.supplier + ',' + resp.simsraT020SiteCityN;
				}
				if (resp.simsahStaSelectedStateProvince != null) {
					this.supplier = this.supplier + ',' + resp.simsahStaSelectedStateProvince;
				} else if (resp.simsraT020StateProvinceC !== null &&
					resp.simsraT020StateProvinceC !== '~!') {
					this.supplier = this.supplier + ',' + resp.simsraT020StateProvinceC;
				}
				if (resp.simsahStaSelectedSitePost != null) {
					this.supplier = this.supplier + ',' + resp.simsahStaSelectedSitePost;
				} else if (resp.simsraT020SitePostC !== null && resp.simsraT020SitePostC !== '~!') {
					this.supplier = this.supplier + ',' + resp.simsraT020SitePostC;
				}
				if (resp.simsahStaSelectedCountry != null) {
					this.supplier = this.supplier + ',' + resp.simsahStaSelectedCountry;
				} else if (resp.simsraT020VatCountryC !== null && resp.simsraT020VatCountryC !== '~!') {
					this.supplier = this.supplier + ',' + resp.simsraT020VatCountryC;
				}
				// this.supplier = resp.simsahSelectedSiteName + ',' + resp.simsahStaSelectedSiteStreetName + ',' +
				// 	resp.simsahStaSelectedSiteCityName + ',' + resp.simsahStaSelectedStateProvince + ',' +
				// 	resp.simsahStaSelectedSitePost + ',' + resp.simsahStaSelectedCountry;
				if (resp.simsahSelectedSiteName != null) {
					this.supplierName = resp.simsahSelectedSiteName;
				} else {
					this.supplierName = resp.simsraT020SiteFullN;
				}
				this.simppapLevel = resp.simsahProdPpapLevel;
				// this.getTreeMapValues(this.siteOption);
			}, error => {
				if (error.error.text === 'Site Code Not Found') {
					this.supplier = '';
					this.supplierName = '';
					this.simppapLevel = '5';
					// this.messageService.add({
					// 	severity: 'error',
					// 	summary: 'Error',
					// 	detail: 'Site Code Not Found'
					// });
				}
			});
		} else {
			this.supplier = '';
		}
	}

	setPhasePlanDisplay(plan, phase){
		if(this.ppapPhase.code === 'ALL') {
			if (plan === 'PLANNED FOR SUBMISSION') {
				this.planDisplay = 'PPAP PLAN: ALL';
			} else if (plan === 'REJECTED PSW') {
				this.planDisplay = 'REJECTED / NEEDS CLARIFICATION';
			} else if (plan === 'APPROVED PSW') {
				this.planDisplay = 'APPROVED / LEVEL 1 SELF APPROVED PSW';
			} else if (plan === 'SUPPLIER CAUSED') {
				this.planDisplay = 'SUPPLIER CAUSED SLIP CODE';
			} else if (plan === 'PD CAUSED') {
				this.planDisplay = 'PD CAUSED SLIP CODE';
			} else if (plan === 'PURCHASE CAUSED') {
				this.planDisplay = 'PURCHASE CAUSED SLIP CODE';
			} else {
				this.planDisplay = sessionStorage.getItem('ppapPlan');
			}
		} else {
			if(plan === 'PLANNED FOR SUBMISSION' && this.ppapPhase.code === 'PBS'){
				this.planDisplay = 'PPAP PLAN:BEYOND 6 MONTH';
			}else if(plan === 'PLANNED FOR SUBMISSION' && this.ppapPhase.code === 'PNS'){
				this.planDisplay = 'PPAP PLAN:NEXT 6 MONTH';
			}else if(plan === 'PLANNED FOR SUBMISSION' && this.ppapPhase.code === 'PTU'){
				this.planDisplay = 'PARTS UNTIMED';
			}else if(plan === 'PLANNED FOR SUBMISSION' && this.ppapPhase.code === 'PPD'){
				this.planDisplay = 'PAST DUE';
			} else {
				if (plan === 'REJECTED PSW') {
					this.planDisplay = 'REJECTED / NEEDS CLARIFICATION';
				} else if (plan === 'APPROVED PSW') {
					this.planDisplay = 'APPROVED / LEVEL 1 SELF APPROVED PSW';
				} else if (plan === 'SUPPLIER CAUSED') {
					this.planDisplay = 'SUPPLIER CAUSED SLIP CODE';
				} else if (plan === 'PD CAUSED') {
					this.planDisplay = 'PD CAUSED SLIP CODE';
				} else if (plan === 'PURCHASE CAUSED') {
					this.planDisplay = 'PURCHASE CAUSED SLIP CODE';
				} else {
					this.planDisplay = sessionStorage.getItem('ppapPlan');
				}
			}
		}
		this.phaseDisplay = phase;
	}

	resetPhasePlanDisplay(){
		this.phaseDisplay = null;
		this.planDisplay = null;
	}

	chartPpapElementSection(){
		this.epswHomepageService.getPpapElementSection(this.phase).subscribe(resp => {
			console.log('Response for ChartPpapElement : ' + resp);
			this.ppapElementSection = resp;
			console.log('PPAP Element :' +this.ppapElementSection);
			this.chartPpapElementQuestion();
		});
	}

	chartPpapElementQuestion(){
		this.epswHomepageService.getPpapElementQuestion(this.phase).subscribe(resp => {
			console.log('Response for ChartPpapElement Question: ' + resp);
			this.ppapElementQuestion = resp;
			console.log('PPAP Element Question :' +this.ppapElementQuestion);
			// const objToSend: NavigationExtras = {
			// 	queryParams: {
			// 		PpapPackageIdentifier :this.ppapPackageIdentifierSave,
			// 		Supplier : this.supplierName,
			// 		PpapElementSection : this.ppapElementSection,
			// 		PpapElementQuestion : this.ppapElementQuestion,
			// 		mode: 'create'
			// 	},
			// 	skipLocationChange: false,
			// 	fragment: 'top'
			// };
			this.ppapPackagePartsForBaseCheck=this.selectedPpapParts;
			const lookup = this.ppapPackagePartsForBaseCheck.reduce((ppapPackagePartsForBaseCheck, ppapPackageParts) => {
				ppapPackagePartsForBaseCheck[ppapPackageParts.noPartBase] = ++ppapPackagePartsForBaseCheck[ppapPackageParts.noPartBase] || 0;
				return ppapPackagePartsForBaseCheck;
			}, {});
			this.countOfBase = this.ppapPackagePartsForBaseCheck.
			filter(ppapPackagePartsForBaseCheck => lookup[ppapPackagePartsForBaseCheck.noPartBase]);
			this.countOfPPAPLevel = this.ppapPackagePartsForBaseCheck.
			filter(ppapPackagePartsForBaseCheck => lookup[ppapPackagePartsForBaseCheck.ppapLevel]);
			if(this.countOfBase.length > 0){
				this.router.navigate(['/DigitalPPAPPackage/'+this.ppapPackageIdentifierSave.ppapPackageIdentifierID+'/create']);
			} else {
				if(this.selectedPpapParts.length>1) {
					this.confirmationService.confirm({
						message: 'Parts selected contains more than one base parts',
						header: 'Confirmation',
						icon: 'fa fa-info-circle',
						accept: () => {
							this.router.navigate(['/DigitalPPAPPackage/' + this.ppapPackageIdentifierSave.ppapPackageIdentifierID+'/create']);
						}
					});
				} else {
					this.router.navigate(['/DigitalPPAPPackage/' + this.ppapPackageIdentifierSave.ppapPackageIdentifierID+'/create']);
				}
			}
		});
	}

	onSelectCheckbox() {
		const selectedRow= this.selectedPpapParts[this.selectedPpapParts.length - 1];
		this.selectedPpapParts.length = 0;
		this.selectedPpapParts.push(selectedRow);
	}

	editDigitialPpapPackage(mode,rowData) {
		this.ppapPackageIdentifierSave.siteCode = this.siteOption;
		this.ppapPackageIdentifierSave.ppapPhase = this.phase;
		console.log('Enter DigitalPPAPPackage');
		console.log(this.selectedPpapParts);
		if(mode === 'edit') {
			this.epswHomepageService.getDataOfSelectedRow(rowData.partsId).subscribe(resp => {
				this.ppapPackageIdentifierSave = resp.ppapPackageIdentifierEntity;
				this.ppapElementQuestion = [];
				const objToSend: NavigationExtras = {
					queryParams: {
						PpapPackageIdentifier: this.ppapPackageIdentifierSave,
						Supplier: this.supplierName,
						PpapElementSection: [],
						PpapElementQuestion: this.ppapElementQuestion,
						mode: 'edit'
					},
					skipLocationChange: false,
					fragment: 'top'
				};
				const status = this.ppapPackageIdentifierSave.ppapPackagePartsEntity[0].status;
				if(status === 'IN PROGRESS' || status === 'REJECTED' || status === 'NEEDS CLARIFICATION')
					this.ppapPackageIdentifierSave.addOrUpdateParts=false;
				//this.getPriorityStatus(this.ppapPackageIdentifierSave.ppapPackagePartsEntity,this.ppapPackageIdentifierSave);
				this.epswHomepageService.saveData(this.ppapPackageIdentifierSave).subscribe(resp => {
					this.ppapPackageIdentifierSave.addOrUpdateParts=false;
					console.log('Save Data: ' + resp);
					this.ppapPackageIdentifierSave = resp;
					this.router.navigate(['/DigitalPPAPPackage/' + this.ppapPackageIdentifierSave.ppapPackageIdentifierID + '/edit']);
				});
			});
		} else {
			this.epswHomepageService.getDataOfSelectedRow(rowData.partsId).subscribe(resp => {
				this.ppapPackageIdentifierSave = resp.ppapPackageIdentifierEntity;
				this.ppapElementQuestion = [];
				// const objToSend: NavigationExtras = {
				// 	queryParams: {
				// 		PpapPackageIdentifier: this.ppapPackageIdentifierSave,
				// 		Supplier: this.supplierName,
				// 		PpapElementSection: [],
				// 		PpapElementQuestion: this.ppapElementQuestion,
				// 		mode: 'view'
				// 	},
				// 	skipLocationChange: false,
				// 	fragment: 'top'
				// };
				this.router.navigate(['/DigitalPPAPPackage/' + this.ppapPackageIdentifierSave.ppapPackageIdentifierID+'/view']);
			});
		}
	}

	updateHighChart(data,beyondSixMonths,nextSixMonths,vppUntimed,pastDue){
		if(data === 'No Data') {
			this.treeMap = [
				{
					name: 'PPAP plan: Beyond 6 month',
					value: 6,
					realValue: data,
					color: 'rgb(0,191,255,0.6)',
				}, {
					name: 'PPAP plan: Next 6 month',
					value: 4,
					realValue: data,
					color: 'rgb(66,165,245,0.5)'
				}, {
					name: 'Parts untimed',
					value: 3,
					realValue: data,
					color: 'rgb(255,191,0,0.5)'
				}, {
					name: 'Past due',
					value: 3,
					realValue: data,
					color: 'rgb(255, 0, 0, 0.7)'
				}];
		} else {
			this.treeMap = [
				{
					name: 'PPAP plan: Beyond 6 month',
					value: 6,
					realValue: beyondSixMonths,
					color: 'rgb(0,191,255,0.6)',
				}, {
					name: 'PPAP plan: Next 6 month',
					value: 4,
					realValue: nextSixMonths,
					color: 'rgb(66,165,245,0.5)'
				}, {
					name: 'Parts untimed',
					value: 3,
					realValue: vppUntimed,
					color: 'rgb(255,191,0,0.5)'
				}, {
					name: 'Past due',
					value: 3,
					realValue: pastDue,
					color: pastDue>0?'rgb(255, 0, 0, 0.7)':'#8ac28a'
				}];
		}
		Highcharts.chart(this.container.nativeElement, {
			// colorAxis: {
			// 	minColor: '#FFFFFF',
			// 	maxColor: Highcharts.getOptions().colors[0]
			// },
			// @ts-ignore
			navigation: {
				buttonOptions: {
					enabled: false
				}
			},
			chart: {
				//renderTo: 'container',
				backgroundColor: null
				//type: 'line'
			},

			tooltip: {
				pointFormatter() {
					// @ts-ignore
					let value = this.realValue;
					if(this.name ==='Parts untimed' && value >= 0){
						value = value+' (Challenge Code 2,3,7 only)';
					}
					return '<b>'
						+ this.name
						+ ':</b>  '
						+ value;
				}
			},
			series: [{
				type: 'treemap',
				// layoutAlgorithm: 'squarified',
				data: this.treeMap,
				events: {
					click(e) {
						console.log(
							//	Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', e.xAxis[0].value),
							//	e.yAxis[0].value
							// @ts-ignore
							e.point.realValue
						);
						// @ts-ignore
						// alert(e.point.realValue)
						// if(event.point.node.childrenTotal == 0 ){//since last node will have zero childrens
						// 	//Perform Task on leaf or last node
						// }
					}
				},
				dataLabels: {
					enabled: true,
					align: 'center',
					style: {
						textOutline: false,
						color: 'black',
						fontSize: '14px'
					},
					formatter() {
						const name = this.point.name;
						// point = this.value,
						//value = this.value;
						console.log(name);

						// return value && point.parent ? key + ': ' + value : key;
						// @ts-ignore
						return '<div style="color: black; font-size: 30px">' + this.point.realValue
							+ ' </div></br><div style="color: black">' + this.point.name + '</div>';
					}
				}

			}]
			,
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
		});
	}

	onChangingSiteCode() {
		this.ppapOption = {name: 'All', code: 'ALLPPAP'};
		this.priorityType = {name: 'All', code: 'ALL'};
		this.noOfPrgCom();
	}


	onChangingPPAPCategory() {
		this.noOfPrgCom();
	}
	// Adding All Option in PPAP Timing
	getAllPPAPTiming(siteCode: string[]) {
		let getBeyondSixPPAPPhase : any;
		let getPastDuePPAPPhase : any;
		let getNextSixPPAPPhase : any;
		let getPartsUntimedPPAPPhase : any;
		let getNoActionRequiredPPAPPhase : any;
		// tslint:disable-next-line:max-line-length
		this.epswHomepageService.getBeyondSixPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp => {
			console.log('Response for BeyondSixPPAPPhase : '+resp);
			console.log('BeyondSixPPAPPhase JSON Resp : '+JSON.stringify(resp))
			getBeyondSixPPAPPhase = resp;
			this.epswHomepageService.getPastDuePPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp1 => {
				console.log('Response for PastDuePPAPPhase : '+resp1);
				console.log('PastDuePPAPPhase get JSON Resp : '+JSON.stringify(resp1))
				console.log('User Role : '+sessionStorage.getItem('userId').toUpperCase())
				getPastDuePPAPPhase = resp1;
				this.epswHomepageService.getNextSixPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp2 => {
					console.log('Response for NextSixPPAPPhase : '+resp2);
					console.log('NextSixPPAPPhase JSON Resp : '+JSON.stringify(resp2))
					getNextSixPPAPPhase = resp2;
					this.epswHomepageService.getPartsUntimedPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp3 => {
						console.log('Response for PartsUntimedPPAPPhase : ' + resp3);
						console.log('PartsUntimedPPAPPhase JSON Resp : '+JSON.stringify(resp3))
						getPartsUntimedPPAPPhase = resp3;
						this.epswHomepageService.getNoActionRequiredPPAPPhase(siteCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp4 => {
							console.log('Response for getNoActionRequiredPPAPPhase : ' + resp4);
							console.log('getNoActionRequiredPPAPPhase JSON Resp : '+JSON.stringify(resp4))
							getNoActionRequiredPPAPPhase = resp4;
							// @ts-ignore
							const allPPAPPhase1: AllPPAPPhase = new AllPPAPPhase();

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0Next15Days = getBeyondSixPPAPPhase.phase0Next15Days + getPastDuePPAPPhase.phase0Next15Days + getNextSixPPAPPhase.phase0Next15Days + getPartsUntimedPPAPPhase.phase0Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1Next15Days = getBeyondSixPPAPPhase.phase1Next15Days + getPastDuePPAPPhase.phase1Next15Days + getNextSixPPAPPhase.phase1Next15Days + getPartsUntimedPPAPPhase.phase1Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2Next15Days = getBeyondSixPPAPPhase.phase2Next15Days + getPastDuePPAPPhase.phase2Next15Days + getNextSixPPAPPhase.phase2Next15Days + getPartsUntimedPPAPPhase.phase2Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3Next15Days = getBeyondSixPPAPPhase.phase3Next15Days + getPastDuePPAPPhase.phase3Next15Days + getNextSixPPAPPhase.phase3Next15Days + getPartsUntimedPPAPPhase.phase3Next15Days;

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PlanSubmission = getBeyondSixPPAPPhase.phase0PlanSubmission + getPastDuePPAPPhase.phase0PlanSubmission + getNextSixPPAPPhase.phase0PlanSubmission + getPartsUntimedPPAPPhase.phase0PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PlanSubmission = getBeyondSixPPAPPhase.phase1PlanSubmission + getPastDuePPAPPhase.phase1PlanSubmission + getNextSixPPAPPhase.phase1PlanSubmission + getPartsUntimedPPAPPhase.phase1PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PlanSubmission = getBeyondSixPPAPPhase.phase2PlanSubmission + getPastDuePPAPPhase.phase2PlanSubmission + getNextSixPPAPPhase.phase2PlanSubmission + getPartsUntimedPPAPPhase.phase2PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PlanSubmission = getBeyondSixPPAPPhase.phase3PlanSubmission + getPastDuePPAPPhase.phase3PlanSubmission + getNextSixPPAPPhase.phase3PlanSubmission + getPartsUntimedPPAPPhase.phase3PlanSubmission;

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0SubOrApprovalPending = getBeyondSixPPAPPhase.phase0SubOrApprovalPending + getPastDuePPAPPhase.phase0SubOrApprovalPending + getNextSixPPAPPhase.phase0SubOrApprovalPending + getPartsUntimedPPAPPhase.phase0SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1SubOrApprovalPending = getBeyondSixPPAPPhase.phase1SubOrApprovalPending + getPastDuePPAPPhase.phase1SubOrApprovalPending + getNextSixPPAPPhase.phase1SubOrApprovalPending + getPartsUntimedPPAPPhase.phase1SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2SubOrApprovalPending = getBeyondSixPPAPPhase.phase2SubOrApprovalPending + getPastDuePPAPPhase.phase2SubOrApprovalPending + getNextSixPPAPPhase.phase2SubOrApprovalPending + getPartsUntimedPPAPPhase.phase2SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3SubOrApprovalPending = getBeyondSixPPAPPhase.phase3SubOrApprovalPending + getPastDuePPAPPhase.phase3SubOrApprovalPending + getNextSixPPAPPhase.phase3SubOrApprovalPending + getPartsUntimedPPAPPhase.phase3SubOrApprovalPending

							//Added for STA/PD Dashboard changes
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase0AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase1AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase2AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase3AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalSTA

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalPD + getPastDuePPAPPhase.phase0AwaitingMyApprovalPD + getNextSixPPAPPhase.phase0AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalPD + getPastDuePPAPPhase.phase1AwaitingMyApprovalPD + getNextSixPPAPPhase.phase1AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalPD + getPastDuePPAPPhase.phase2AwaitingMyApprovalPD + getNextSixPPAPPhase.phase2AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalPD + getPastDuePPAPPhase.phase3AwaitingMyApprovalPD + getNextSixPPAPPhase.phase3AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalPD

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0SupplierCausedSlip = getBeyondSixPPAPPhase.phase0SupplierCausedSlip + getPastDuePPAPPhase.phase0SupplierCausedSlip + getNextSixPPAPPhase.phase0SupplierCausedSlip + getPartsUntimedPPAPPhase.phase0SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1SupplierCausedSlip = getBeyondSixPPAPPhase.phase1SupplierCausedSlip + getPastDuePPAPPhase.phase1SupplierCausedSlip + getNextSixPPAPPhase.phase1SupplierCausedSlip + getPartsUntimedPPAPPhase.phase1SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2SupplierCausedSlip = getBeyondSixPPAPPhase.phase2SupplierCausedSlip + getPastDuePPAPPhase.phase2SupplierCausedSlip + getNextSixPPAPPhase.phase2SupplierCausedSlip + getPartsUntimedPPAPPhase.phase2SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3SupplierCausedSlip = getBeyondSixPPAPPhase.phase3SupplierCausedSlip + getPastDuePPAPPhase.phase3SupplierCausedSlip + getNextSixPPAPPhase.phase3SupplierCausedSlip + getPartsUntimedPPAPPhase.phase3SupplierCausedSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PDCausedSlip = getBeyondSixPPAPPhase.phase0PDCausedSlip + getPastDuePPAPPhase.phase0PDCausedSlip + getNextSixPPAPPhase.phase0PDCausedSlip + getPartsUntimedPPAPPhase.phase0PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PDCausedSlip = getBeyondSixPPAPPhase.phase1PDCausedSlip + getPastDuePPAPPhase.phase1PDCausedSlip + getNextSixPPAPPhase.phase1PDCausedSlip + getPartsUntimedPPAPPhase.phase1PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PDCausedSlip = getBeyondSixPPAPPhase.phase2PDCausedSlip + getPastDuePPAPPhase.phase2PDCausedSlip + getNextSixPPAPPhase.phase2PDCausedSlip + getPartsUntimedPPAPPhase.phase2PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PDCausedSlip = getBeyondSixPPAPPhase.phase3PDCausedSlip + getPastDuePPAPPhase.phase3PDCausedSlip + getNextSixPPAPPhase.phase3PDCausedSlip + getPartsUntimedPPAPPhase.phase3PDCausedSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PurchaseSlip = getBeyondSixPPAPPhase.phase0PurchaseSlip + getPastDuePPAPPhase.phase0PurchaseSlip + getNextSixPPAPPhase.phase0PurchaseSlip + getPartsUntimedPPAPPhase.phase0PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PurchaseSlip = getBeyondSixPPAPPhase.phase1PurchaseSlip + getPastDuePPAPPhase.phase1PurchaseSlip + getNextSixPPAPPhase.phase1PurchaseSlip + getPartsUntimedPPAPPhase.phase1PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PurchaseSlip = getBeyondSixPPAPPhase.phase2PurchaseSlip + getPastDuePPAPPhase.phase2PurchaseSlip + getNextSixPPAPPhase.phase2PurchaseSlip + getPartsUntimedPPAPPhase.phase2PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PurchaseSlip = getBeyondSixPPAPPhase.phase3PurchaseSlip + getPastDuePPAPPhase.phase3PurchaseSlip + getNextSixPPAPPhase.phase3PurchaseSlip + getPartsUntimedPPAPPhase.phase3PurchaseSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0OtherSlips = getBeyondSixPPAPPhase.phase0OtherSlips + getPastDuePPAPPhase.phase0OtherSlips + getNextSixPPAPPhase.phase0OtherSlips + getPartsUntimedPPAPPhase.phase0OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1OtherSlips = getBeyondSixPPAPPhase.phase1OtherSlips + getPastDuePPAPPhase.phase1OtherSlips + getNextSixPPAPPhase.phase1OtherSlips + getPartsUntimedPPAPPhase.phase1OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2OtherSlips = getBeyondSixPPAPPhase.phase2OtherSlips + getPastDuePPAPPhase.phase2OtherSlips + getNextSixPPAPPhase.phase2OtherSlips + getPartsUntimedPPAPPhase.phase2OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3OtherSlips = getBeyondSixPPAPPhase.phase3OtherSlips + getPastDuePPAPPhase.phase3OtherSlips + getNextSixPPAPPhase.phase3OtherSlips + getPartsUntimedPPAPPhase.phase3OtherSlips

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0RejectedPSW = getBeyondSixPPAPPhase.phase0RejectedPSW + getPastDuePPAPPhase.phase0RejectedPSW + getNextSixPPAPPhase.phase0RejectedPSW + getPartsUntimedPPAPPhase.phase0RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1RejectedPSW = getBeyondSixPPAPPhase.phase1RejectedPSW + getPastDuePPAPPhase.phase1RejectedPSW + getNextSixPPAPPhase.phase1RejectedPSW + getPartsUntimedPPAPPhase.phase1RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2RejectedPSW = getBeyondSixPPAPPhase.phase2RejectedPSW + getPastDuePPAPPhase.phase2RejectedPSW + getNextSixPPAPPhase.phase2RejectedPSW + getPartsUntimedPPAPPhase.phase2RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3RejectedPSW = getBeyondSixPPAPPhase.phase3RejectedPSW + getPastDuePPAPPhase.phase3RejectedPSW + getNextSixPPAPPhase.phase3RejectedPSW + getPartsUntimedPPAPPhase.phase3RejectedPSW

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0ApprovedPSW = getBeyondSixPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1ApprovedPSW = getBeyondSixPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2ApprovedPSW = getBeyondSixPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3ApprovedPSW = getBeyondSixPPAPPhase.phase3ApprovedPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW
							//Added for US3832060
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase0NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase1NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase2NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase3NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW

							this.allPPAPPhase = allPPAPPhase1;

						});
					});
				});
			});
		});
	}

	//Added for US3832060
	noActionRequired() {
		if (this.cmmsPartsPPap != null && this.cmmsPartsPPap !== undefined) {
			this.displayCommentsForNoActionRequired = true;
			this.noActionRequiredComments = '';
			if (this.selectedPpapParts.length !== 0) {
				this.displayCommentsForNoActionRequired = true;
				this.noActionRequiredComments = '';
			} else {
				this.displayCommentsForNoActionRequired = false;
				this.messageService.add({
					severity: 'info',
					summary: 'Info',
					detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package',
					life: 5000
				});
			}
			if(this.noActionRequiredComments.length > 0) {
				this.isdisableNA = false;
			} else {
				this.isdisableNA = true;
			}
		} else {
			this.displayCommentsForNoActionRequired = false;
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package',
				life: 5000
			});
		}
	}

	saveNoActionRequiredData() {
		if (this.cmmsPartsPPap != null && this.cmmsPartsPPap !== undefined) {
			console.log(this.siteOption);
			this.ppapPackageIdentifierSave.siteCode = this.siteOption;
			this.ppapPackageIdentifierSave.ppapPhase = this.phase;
			this.ppapPackageIdentifierSave.ppapPhaseCode = this.ppapPhase.code;
			this.ppapPackageIdentifierSave.ppapPhasePlan = this.plan;
			this.ppapPackageIdentifierSave.familyPriorityStatus = this.s;
			// this.ppapPackageIdentifierSave.pdApprovalRequired = undefined;
			console.log('before call' + this.ppapPackageParts);
			this.ppapPackageParts = [];
			if (this.selectedPpapParts.length !== 0) {
				for (const currentRow of this.selectedPpapParts) {
					if (this.ppapLevelOption.name !== this.simppapLevel && this.ppapLevelOption.name !== '') {
						this.ppapPackageParts.push({
							capDt: new Date(currentRow.dtCapVerif),
							noPartBase: currentRow.noPartBase,
							noPartPrefix: currentRow.noPartPrefix,
							noPartSuffix: currentRow.noPartSuffix,
							ppapLevel: this.ppapLevelOption.name,
							staApprovalRequired: currentRow.staApprovalRequired,
							priorityStatus: currentRow.priorityStatus,
							ppapPackageIdentifierID: null,
							ppapPackagePartsEntityID: null,
							prodDt: new Date(currentRow.dtProdVerif),
							partDescription: currentRow.partDescription,
							qualDt: new Date(currentRow.dtQualVerif),
							rarDt: new Date(currentRow.dtRatrVerif),
							select: '',
							noLstNotice: currentRow.noLstNotice,
							dtLstNotice: null,
							status: 'NO ACTION REQUIRED',
							programCode: currentRow.programCode,
							nextAuditLayout: '',
							isFormCompleted: '',
							submittedEmailId: '',
							submittedCdsid: '',
							submittedBy: '',
							submittedOn: null,
							ppapStaStatus: '',
							ppapStaStatusBy: '',
							ppapStaStatusOn: null,
							ppapStaComments: '',
							ppapPdStatus: '',
							ppapPdStatusBy: '',
							ppapPdStatusOn: null,
							ppapPdComments: '',
							overallPpapStatus: '',
							pswStaStatus: '',
							pswStaStatusBy: '',
							pswStaUserId: '',
							pswStaStatusOn: null,
							pswStaComments: '',
							pswPdStatus: '',
							pswPdStatusBy: '',
							pswPdUserId: '',
							pswPdStatusOn: null,
							pswPdComments: '',
							overallPswStatus: '',
							lastUpdatedBy: '',
							lastUpdatedOn: null,
							partNumber: currentRow.noPartPrefix + '-' + currentRow.noPartBase + '-' + currentRow.noPartSuffix,
							phase1Phase2: null,
							createdBy: '',
							createdOn: null,
							programName: currentRow.programName,
							naComments: this.noActionRequiredComments,
							familyPswId: null,
							familyPswOrgPartNumber: '',
							familyPswPoNumber: '',
							familyPswWeight: '',
							familyPswIMDSIDNo: '',
							familyPswOptional1: '',
							familyPswOptional2: '',
							familyPswOptional3: '',
							familyPswOptional4: '',
							familyPswOptional5: '',
							recallComments: null,
							recallDate: null,
							recallFlow: null,
							recallBy: null,
							cdSupplierShpFr: currentRow.cdSupplierShpFr,
							programDescription: currentRow.programDescription

						});
					} else {
						this.ppapPackageParts.push({
							capDt: new Date(currentRow.dtCapVerif),
							noPartBase: currentRow.noPartBase,
							noPartPrefix: currentRow.noPartPrefix,
							noPartSuffix: currentRow.noPartSuffix,
							ppapLevel: this.simppapLevel,
							ppapPackageIdentifierID: null,
							staApprovalRequired: currentRow.staApprovalRequired,
							priorityStatus: currentRow.priorityStatus,
							ppapPackagePartsEntityID: null,
							prodDt: new Date(currentRow.dtProdVerif),
							partDescription: currentRow.partDescription,
							qualDt: new Date(currentRow.dtQualVerif),
							rarDt: new Date(currentRow.dtRatrVerif),
							select: '',
							noLstNotice: currentRow.noLstNotice,
							dtLstNotice: null,
							status: 'NO ACTION REQUIRED',
							programCode: currentRow.programCode,
							nextAuditLayout: '',
							isFormCompleted: '',
							submittedEmailId: '',
							submittedCdsid: '',
							submittedBy: '',
							submittedOn: null,
							ppapStaStatus: '',
							ppapStaStatusBy: '',
							ppapStaStatusOn: null,
							ppapStaComments: '',
							ppapPdStatus: '',
							ppapPdStatusBy: '',
							ppapPdStatusOn: null,
							ppapPdComments: '',
							overallPpapStatus: '',
							pswStaStatus: '',
							pswStaStatusBy: '',
							pswStaUserId: '',
							pswStaStatusOn: null,
							pswStaComments: '',
							pswPdStatus: '',
							pswPdStatusBy: '',
							pswPdUserId: '',
							pswPdStatusOn: null,
							pswPdComments: '',
							overallPswStatus: '',
							partNumber: currentRow.noPartPrefix + '-' + currentRow.noPartBase + '-' + currentRow.noPartSuffix,
							phase1Phase2: null,
							naComments: this.noActionRequiredComments,
							lastUpdatedBy: '',
							lastUpdatedOn: null,
							createdBy: '',
							createdOn: null,
							programName: currentRow.programName,
							familyPswId: null,
							familyPswOrgPartNumber: '',
							familyPswPoNumber: '',
							familyPswWeight: '',
							familyPswIMDSIDNo: '',
							familyPswOptional1: '',
							familyPswOptional2: '',
							familyPswOptional3: '',
							familyPswOptional4: '',
							familyPswOptional5: '',
							recallComments: null,
							recallDate: null,
							recallFlow: null,
							recallBy: null,
							cdSupplierShpFr: currentRow.cdSupplierShpFr,
							programDescription: currentRow.programDescription
						});
					}
				}
				this.ppapPackageIdentifierSave.ppapPackagePartsEntity = this.ppapPackageParts;
				this.ppapPackageIdentifierSave.addOrUpdateParts=false;
				this.epswHomepageService.saveData(this.ppapPackageIdentifierSave).subscribe(resp => {
					console.log('Save Data: ' + resp);
					// this.ppapPackageIdentifierSave = resp;
					this.getTreeMapValues(this.siteCodes);
					this.getAllPPAPTiming(this.siteCodes);
					this.epswHomepageService.getData(this.siteCodes, this.ppapPackageIdentifierSave.ppapPhase,
						this.ppapPackageIdentifierSave.ppapPhasePlan, this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(respOne => {
						this.cmmsPartsPPap = respOne;
						console.log(respOne);
						a1:for(const data of this.cmmsPartsPPap){
							if(data.status === 'PENDING'){
								if(this.phase ==='Phase0' && data.cdRatrVerif !==  ''){
									this.checkedStatus = true;
									break a1;
								} else if(this.phase ==='Phase1' && data.cdQualVerif !==  ''){
									this.checkedStatus = true;
									break a1;
								} else if(this.phase ==='Phase2' && data.cdProdVerif !==  ''){
									this.checkedStatus = true;
									break a1;
								} else if(this.phase ==='Phase3' && data.cdCapVerif !==  ''){
									this.checkedStatus = true;
									break a1;
								}else {
									this.checkedStatus = false;
								}
							}else {
								this.checkedStatus = false;
							}
							this.checkStatus = data.status;
						}
					});
					// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
					// this.router.onSameUrlNavigation = 'reload';
					// this.router.navigate(['./'], {relativeTo: this.route});
				});
			} else {
				this.messageService.add({
					severity: 'info',
					summary: 'Info',
					detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package'
				});
			}
		} else {
			this.messageService.add({
				severity: 'info',
				summary: 'Info',
				detail: 'Please Select Check Box Single (or) Family of Part Numbers Applicable for this PPAP Package',
				life: 5000
			});
		}
		this.displayCommentsForNoActionRequired = false;
	}

	cancelNoActionRequiredData() {
		this.noActionRequiredComments = '';
		this.displayCommentsForNoActionRequired = false;
	}

	revokeDigitalPpapPackage(rowData: any) {
		this.confirmationService.confirm({
			message: 'After revoking the NO ACTION REQUIRED status,the part will set to PENDING status which will required PPAP Submission.\n' +
				'Are you sure you want to proceed ???',
			header: 'Close',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.digitalPpapPackageService.getAllPartsData(rowData.noPartPrefix, rowData.noPartBase,
					rowData.noPartSuffix,rowData.partsId).subscribe(resp => {
					this.entityID = resp.genericInt;
					this.digitalPpapPackageService.deletePpapPart(this.entityID, rowData.partsId).subscribe(respOne => {
						this.getTreeMapValues(this.siteCodes);
						this.getAllPPAPTiming(this.siteCodes);
						this.epswHomepageService.getData(this.siteCodes, this.phase, this.plan,
							this.ppapPhase.code, this.ppapOption.name, this.priorityType.code).subscribe(resp1 => {
							this.cmmsPartsPPap = resp1;
							console.log(resp);
							a1:for(const data of this.cmmsPartsPPap){
								if(data.status === 'PENDING'){
									if(this.phase ==='Phase0' && data.cdRatrVerif !==  ''){
										this.checkedStatus = true;
										break a1;
									} else if(this.phase ==='Phase1' && data.cdQualVerif !==  ''){
										this.checkedStatus = true;
										break a1;
									} else if(this.phase ==='Phase2' && data.cdProdVerif !==  ''){
										this.checkedStatus = true;
										break a1;
									} else if(this.phase ==='Phase3' && data.cdCapVerif !==  ''){
										this.checkedStatus = true;
										break a1;
									}else {
										this.checkedStatus = false;
									}
								}else {
									this.checkedStatus = false;
								}
								this.checkStatus = data.status;
							}
						});
					});
				});
				// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
				// this.router.onSameUrlNavigation = 'reload';
				// this.router.navigate(['./'], { relativeTo: this.route });
			},
			reject: () => {
			}
		});
	}
	getNoActionRequitedTooltip(rowData: any) {
		this.digitalPpapPackageService.getAllPartsData(rowData.noPartPrefix, rowData.noPartBase,
			rowData.noPartSuffix,rowData.partsId).subscribe(resp => {
			this.entityID = resp.genericInt;
			this.supplierViewService.getAllData(this.entityID).subscribe(respOne => {
				this.ppapPartsData = respOne;
				this.noActionRequiredComments = this.ppapPartsData.naComments;
				this.dispalySavedComments = true;
			});
		});
	}

	noActionRequiredButtonEnable() {
		if(this.noActionRequiredComments.length > 0) {
			this.isdisableNA = false;
		} else {
			this.isdisableNA = true;
		}
	}

	checkSelectedParts(){
		if (this.selectedPpapParts.length !== 0) {
			for (const currentRow of this.selectedPpapParts) {
				this.partsArray.push(currentRow);
			}
			const set = new Set(this.partsArray);
			this.partsArray = Array.from(set);
			this.selectedPpapParts=[];
			this.selectedPpapParts=this.partsArray;
		} else {
			this.selectedPpapParts = [];
			this.partsArray = [];
		}
	}

	onCheckSelectedParts(){
		this.partsArray = [];
		if (this.selectedPpapParts.length !== 0) {
			for (const currentRow of this.selectedPpapParts) {
				this.partsArray.push(currentRow);
			}
		} else {
			this.selectedPpapParts = [];
			this.partsArray = [];
		}
	}

	displaySiteCode(e) {
		const isCheckedSite = e.checked;
		if (isCheckedSite){
			sessionStorage.setItem('viewBy','site');
			this.showSiteValues = true;
			this.showProgramValues = false;
			this.checked1 = false;
		} else {
			sessionStorage.setItem('viewBy','program');
			this.showSiteValues = false;
			this.showProgramValues = true;
			this.checked1 = true;
		}
		this.ppapPhase = {name: 'All', code: 'ALL'};
		this.priorityType = {name: 'All', code: 'ALL'};
		if (!this.pswAccessControl.get('isSupplier') && this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin' && this.userRole !== 'Buyer'
			&& this.userRole !== 'STASrProgramManager' && this.userRole !== 'STAProgramEngineer' && this.userRole !== 'STAProgramManager'
			&& this.userRole !== 'MPLAnalyst' && this.userRole !== 'ePSW View Only') {
			// this.site.push({label: 'ALL', value: 'ALL'});
			// For ALL as Default option
			// if(this.userRole !== 'STASuperUser' && this.userRole !== 'ePSW Admin'){
			this.siteOption = 'ALL';
			this.getNoOfProgram(this.siteOption);
			this.getNoOfCommodities(this.siteOption);
			this.getSupplierName(this.siteOption);
			this.getTreeMapValues(this.siteOption);
			// this.siteCodes.push(this.siteOption);
			this.getAllPPAPTiming(this.siteCodeList);
			// if (this.ppapPhase.code === 'ALL') {
			// 	console.log('ALL Type PPAP Phase');
			// 	// this.getAllPPAPPhase(this.siteOption);
			// 	if (this.siteOption !== 'ALL') {
			// 		this.siteCodes = [];
			// 		this.siteCodes.push(this.siteOption);
			// 		this.getAllPPAPTiming(this.siteCodes);
			// 	}
			// }
		} else {
			this.siteOption = [];
		}
		this.supplier = '';
		this.cmmsPartsPPap = [];
		this.updateHighChart('No Data',0,0,0,0);
		this.allPPAPPhase.phase0Next15Days = 0;
		this.allPPAPPhase.phase1Next15Days = 0;
		this.allPPAPPhase.phase2Next15Days = 0;
		this.allPPAPPhase.phase3Next15Days = 0;
		this.allPPAPPhase.phase0PlanSubmission = 0;
		this.allPPAPPhase.phase1PlanSubmission = 0;
		this.allPPAPPhase.phase2PlanSubmission = 0;
		this.allPPAPPhase.phase3PlanSubmission = 0;
		this.allPPAPPhase.phase0SubOrApprovalPending = 0;
		this.allPPAPPhase.phase1SubOrApprovalPending = 0;
		this.allPPAPPhase.phase2SubOrApprovalPending = 0;
		this.allPPAPPhase.phase3SubOrApprovalPending = 0;
		this.allPPAPPhase.phase0SupplierCausedSlip = 0;
		this.allPPAPPhase.phase1SupplierCausedSlip = 0;
		this.allPPAPPhase.phase2SupplierCausedSlip = 0;
		this.allPPAPPhase.phase3SupplierCausedSlip = 0;
		this.allPPAPPhase.phase0PDCausedSlip = 0;
		this.allPPAPPhase.phase1PDCausedSlip = 0;
		this.allPPAPPhase.phase2PDCausedSlip = 0;
		this.allPPAPPhase.phase3PDCausedSlip = 0;
		this.allPPAPPhase.phase0PurchaseSlip = 0;
		this.allPPAPPhase.phase1PurchaseSlip = 0;
		this.allPPAPPhase.phase2PurchaseSlip = 0;
		this.allPPAPPhase.phase3PurchaseSlip = 0;
		this.allPPAPPhase.phase0OtherSlips = 0;
		this.allPPAPPhase.phase1OtherSlips = 0;
		this.allPPAPPhase.phase2OtherSlips = 0;
		this.allPPAPPhase.phase3OtherSlips = 0;
		this.allPPAPPhase.phase0RejectedPSW = 0;
		this.allPPAPPhase.phase1RejectedPSW = 0;
		this.allPPAPPhase.phase2RejectedPSW = 0;
		this.allPPAPPhase.phase3RejectedPSW = 0;
		this.allPPAPPhase.phase0ApprovedPSW = 0;
		this.allPPAPPhase.phase1ApprovedPSW = 0;
		this.allPPAPPhase.phase2ApprovedPSW = 0;
		this.allPPAPPhase.phase3ApprovedPSW = 0;
		//Added for US3832060
		this.allPPAPPhase.phase0NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase1NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase2NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase3NoActionRequiredPSW = 0;

		this.allPPAPPhase.phase0AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase1AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase2AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase3AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase0AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase1AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase2AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase3AwaitingMyApprovalPD = 0;
	}

	displayProgram(e) {
		const isChecked = e.checked;
		if (isChecked){
			sessionStorage.setItem('viewBy','program');
			this.showProgramValues = true;
			this.showSiteValues = false;
			this.enableReportDownload = false;
			this.checked2 = false;
		} else {
			sessionStorage.setItem('viewBy','site');
			this.showProgramValues = false;
			this.showSiteValues = true;
			this.checked2 = true;
		}
		sessionStorage.removeItem('programDropdown');
		this.siteOption = undefined;
		this.programData = '';
		// this.programOption = [];
		this.programDescription = '';
		this.cmmsPartsPPap = [];
		this.ppapPhase = {name: 'All', code: 'ALL'};
		this.priorityType = {name: 'All', code: 'ALL'};
		// this.getProgramCodeValues();
		this.updateHighChart('No Data',0,0,0,0);
		this.allPPAPPhase.phase0Next15Days = 0;
		this.allPPAPPhase.phase1Next15Days = 0;
		this.allPPAPPhase.phase2Next15Days = 0;
		this.allPPAPPhase.phase3Next15Days = 0;
		this.allPPAPPhase.phase0PlanSubmission = 0;
		this.allPPAPPhase.phase1PlanSubmission = 0;
		this.allPPAPPhase.phase2PlanSubmission = 0;
		this.allPPAPPhase.phase3PlanSubmission = 0;
		this.allPPAPPhase.phase0SubOrApprovalPending = 0;
		this.allPPAPPhase.phase1SubOrApprovalPending = 0;
		this.allPPAPPhase.phase2SubOrApprovalPending = 0;
		this.allPPAPPhase.phase3SubOrApprovalPending = 0;
		this.allPPAPPhase.phase0SupplierCausedSlip = 0;
		this.allPPAPPhase.phase1SupplierCausedSlip = 0;
		this.allPPAPPhase.phase2SupplierCausedSlip = 0;
		this.allPPAPPhase.phase3SupplierCausedSlip = 0;
		this.allPPAPPhase.phase0PDCausedSlip = 0;
		this.allPPAPPhase.phase1PDCausedSlip = 0;
		this.allPPAPPhase.phase2PDCausedSlip = 0;
		this.allPPAPPhase.phase3PDCausedSlip = 0;
		this.allPPAPPhase.phase0PurchaseSlip = 0;
		this.allPPAPPhase.phase1PurchaseSlip = 0;
		this.allPPAPPhase.phase2PurchaseSlip = 0;
		this.allPPAPPhase.phase3PurchaseSlip = 0;
		this.allPPAPPhase.phase0OtherSlips = 0;
		this.allPPAPPhase.phase1OtherSlips = 0;
		this.allPPAPPhase.phase2OtherSlips = 0;
		this.allPPAPPhase.phase3OtherSlips = 0;
		this.allPPAPPhase.phase0RejectedPSW = 0;
		this.allPPAPPhase.phase1RejectedPSW = 0;
		this.allPPAPPhase.phase2RejectedPSW = 0;
		this.allPPAPPhase.phase3RejectedPSW = 0;
		this.allPPAPPhase.phase0ApprovedPSW = 0;
		this.allPPAPPhase.phase1ApprovedPSW = 0;
		this.allPPAPPhase.phase2ApprovedPSW = 0;
		this.allPPAPPhase.phase3ApprovedPSW = 0;
		//Added for US3832060
		this.allPPAPPhase.phase0NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase1NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase2NoActionRequiredPSW = 0;
		this.allPPAPPhase.phase3NoActionRequiredPSW = 0;

		this.allPPAPPhase.phase0AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase1AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase2AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase3AwaitingMyApprovalSTA = 0;
		this.allPPAPPhase.phase0AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase1AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase2AwaitingMyApprovalPD = 0;
		this.allPPAPPhase.phase3AwaitingMyApprovalPD = 0;
	}

	getProgramCodeValues() {
		console.log('Site',this.site);
		console.log('Site',this.siteOption);
		this.programOption = [];
		if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
			|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser'||this.userRole ==='ePSW View Only') {
			this.epswHomepageService.getProgramCodeValues().subscribe(value => {
				this.dataValue = value.mapOfStringListMap['programList'];
				if (this.dataValue !== undefined && this.dataValue.length !== 0) {

					for (const dataOne of this.dataValue) {
						if (this.dataValue !== undefined && this.dataValue.length !== 0) {
							this.programOption.push({
								label: dataOne['tepsw_batch_001_program_name'],
								value: dataOne['tepsw_batch_001_program_name']
							});

						}
					}
				}
				this.epswHomepageService.getProgramCodeSiteValuesFromParts().subscribe(value1 => {
					this.dataValue = value1.mapOfStringListMap['programList'];
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						for (const dataOne of this.dataValue) {
							if (this.dataValue !== undefined && this.dataValue.length !== 0) {
								this.programOption.push({
									label: dataOne['tepsw_batch_001_program_name'],
									value: dataOne['tepsw_batch_001_program_name']
								});
							}
						}
					}
					this.programOption = this.programOption.reduce((accumulator, current) => {
						if (
							!accumulator.some(
								(item) => item.value === current.value,
							)
						) {
							accumulator.push(current);
						}
						return accumulator;
					}, []);
					if (sessionStorage.getItem('viewBy') === 'program') {
						this.programData = sessionStorage.getItem('programDropdown');
						this.getProgramDescriptionValues(this.programData);
					}
				});
			});
		} else if(this.userRole === 'PDEngineer' || this.userRole === 'STASiteEngineer' ||
			this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
			this.userRole === 'APQPSiteSTAEngineer'){
			this.epswHomepageService.getProgramCodeSiteValues(this.siteList).subscribe(value => {
				this.dataValue = value.mapOfStringList['programSiteList'];
				for (const dataOne of this.dataValue) {
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						this.programOption.push({
							label: dataOne[1],
							value: dataOne[1]
						});
					}
				}
				// this.epswHomepageService.getProgramCodeSiteValuesFromParts(this.siteList).subscribe(value => {
				// 	this.dataValue = value['programSiteList'];
				// 	for (const dataOne of this.dataValue) {
				// 		if (this.dataValue !== undefined && this.dataValue.length !== 0) {
				// 			this.programOption.push({
				// 				label: dataOne[1],
				// 				value: dataOne[1]
				// 			});
				// 		}
				// 	}

					this.programOption = this.programOption.reduce((accumulator, current) => {
						if (
							!accumulator.some(
								(item) => item.value === current.value,
							)
						) {
							accumulator.push(current);
						}
						return accumulator;
					}, []);

					if (sessionStorage.getItem('viewBy') === 'program') {
						this.programData = sessionStorage.getItem('programDropdown');
						this.getProgramDescriptionValues(this.programData);
					}
				});
			// });
		}
	}

	getProgramDescriptionValues(program: any) {
		sessionStorage.setItem('programDropdown',program);
		console.log('getProgramDescriptionValues ',program);
		if(this.checked1 === false) {
			this.checked1 = true;
			this.checked2 = false;
			this.showSiteValues = false;
			this.showProgramValues = true;
		}
		this.enableReportDownload = true;
		this.cmmsPartsPPap = [];
		this.ppapPhase = {name: 'All', code: 'ALL'};
		this.priorityType = {name: 'All', code: 'ALL'};
		this.ppapOption = {name: 'All', code: 'ALLPPAP'};
		if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
			|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser'||this.userRole ==='ePSW View Only') {
			     // this.epswHomepageService.getSiteCodesFromProgram(program, this.siteList).subscribe(siteCodes => {
			    // 	this.programCodeSiteCodes.push(siteCodes);
				this.epswHomepageService.getProgramCodeValues().subscribe(value => {
					this.dataValue = value.mapOfStringListMap['programList'];
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						for (const dataOne of this.dataValue) {
							if (this.dataValue !== undefined && this.dataValue.length !== 0) {
								if (dataOne['tepsw_batch_001_program_name'] === program) {
									this.programDescription = dataOne['tepsw_batch_001_program_description'];
									console.log(dataOne['tepsw_batch_001_program_description']);
									this.programCodes = dataOne['tepsw_batch_001_program_code'];
									sessionStorage.setItem('programCode', this.programCodes);
								}
							}
						}
					}
					if (program !== null)
						this.epswHomepageService.getProgramCodeSiteValuesFromParts().subscribe(value1 => {
							this.dataValue = value1.mapOfStringListMap['programList'];
							if (this.dataValue !== undefined && this.dataValue.length !== 0) {
								for (const dataOne of this.dataValue) {
									if (this.dataValue !== undefined && this.dataValue.length !== 0) {
										if (dataOne['tepsw_batch_001_program_name'] === program) {
											if (this.programCodes !== dataOne['tepsw_batch_001_program_name']) {
												this.programDescription = dataOne['tepsw_batch_001_program_description'];
												console.log(this.programDescription);
												this.programCodes = dataOne['tepsw_batch_001_program_code'];
											}
											sessionStorage.setItem('programCode', this.programCodes);
										}
									}
								}
							}
						console.log(this.programDescription);
							this.getProgramTreeMapValues(this.programCodes);
							this.getAllPrgmPPAPTiming(this.programCodes);
						});
				});

			//});

		} else if(this.userRole === 'PDEngineer' || this.userRole === 'STASiteEngineer' ||
			this.userRole === 'STASiteSupervisor' || this.userRole ===  'STASiteManager' ||
			this.userRole === 'APQPSiteSTAEngineer'){
			this.epswHomepageService.getProgramCodeSiteValues(this.siteList).subscribe(value => {
				this.dataValue = value.mapOfStringList['programSiteList'];
				for (const dataOne of this.dataValue) {
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						if (dataOne[1] === program) {
							this.programDescription = dataOne[2];
							this.programCodes = dataOne[0];
							sessionStorage.setItem('programCode', this.programCodes);
						}
					}
				}
				this.epswHomepageService.getSiteCodesFromProgram(program,this.siteList).subscribe( resp => {
					this.siteValues = resp;
					console.log('4032',this.siteValues);
					this.getProgramSiteTreeMapValues(this.programCodes,this.siteValues);
					this.getAllPrgmSitePPAPTiming(this.programCodes,this.siteValues);
				});
			});
		}
	}

	private getProgramTreeMapValues(programCodes: any) {
		// this.epswHomepageService.getSiteCodesFromProgramCode(programCodes,this.siteList).subscribe(siteCodes => {
		// 	this.programCodeSiteCodes.push(siteCodes);

			// tslint:disable-next-line:max-line-length

			this.epswHomepageService.getPgmBeyondSixMonthValues(programCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
				console.log('Response for Beyond Six Month : ' + resp);
				this.beyondSixMonths = resp;
				// tslint:disable-next-line:max-line-length
				this.epswHomepageService.getPgmPastDueValues(programCodes ,this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(pastDueResp => {
					console.log('Response for Past Due : ' + pastDueResp);
					this.pastDue = pastDueResp;
					// tslint:disable-next-line:max-line-length
					this.epswHomepageService.getPgmNextSixMonthValues(programCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(nextSixMonthResp => {
						console.log('Response for Next Six Months : ' + nextSixMonthResp);
						this.nextSixMonths = nextSixMonthResp;
						// tslint:disable-next-line:max-line-length
						this.epswHomepageService.getPgmPartsUntimedValues(programCodes, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase()).subscribe(vppUntimedResp => {
							console.log('Response for VPP Untimed : ' + vppUntimedResp);
							this.vppUntimed = vppUntimedResp;
							// this.totalPartCount = this.beyondSixMonths + this.pastDue + this.nextSixMonths + this.vppUntimed;
							this.updateHighChart('yes', this.beyondSixMonths, this.nextSixMonths, this.vppUntimed, this.pastDue);
						});
					});
				});
			});
		//});
	}

	// Adding All Option in PPAP Timing
	getAllPrgmPPAPTiming(programCode: string[]) {
		// this.epswHomepageService.getSiteCodesFromProgramCode(this.programCodes,this.siteList).subscribe(siteCodes => {
		// 	this.programCodeSiteCodes.push(siteCodes);

			let getBeyondSixPPAPPhase: any;
			let getPastDuePPAPPhase: any;
			let getNextSixPPAPPhase: any;
			let getPartsUntimedPPAPPhase: any;
			let getNoActionRequiredPPAPPhase: any;
			// tslint:disable-next-line:max-line-length

			this.epswHomepageService.getPrgmBeyondSixPPAPPhase(programCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
				console.log('Response for BeyondSixPPAPPhase : ' + resp);
				console.log('BeyondSixPPAPPhase JSON Resp : ' + JSON.stringify(resp))
				getBeyondSixPPAPPhase = resp;
				this.epswHomepageService.getPrgmPastDuePPAPPhase(programCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp1 => {
					console.log('Response for PastDuePPAPPhase : ' + resp1);
					console.log('PastDuePPAPPhase get JSON Resp : ' + JSON.stringify(resp1))
					console.log('User Role : ' + sessionStorage.getItem('userId').toUpperCase())
					getPastDuePPAPPhase = resp1;
					this.epswHomepageService.getPrgmNextSixPPAPPhase(programCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp2 => {
						console.log('Response for NextSixPPAPPhase : ' + resp2);
						console.log('NextSixPPAPPhase JSON Resp : ' + JSON.stringify(resp2))
						getNextSixPPAPPhase = resp2;
						this.epswHomepageService.getPrgmPartsUntimedPPAPPhase(programCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp3 => {
							console.log('Response for PartsUntimedPPAPPhase : ' + resp3);
							console.log('PartsUntimedPPAPPhase JSON Resp : ' + JSON.stringify(resp3))
							getPartsUntimedPPAPPhase = resp3;
							this.epswHomepageService.getPrgmNoActionRequiredPPAPPhase(programCode, this.ppapOption.name, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp4 => {
								console.log('Response for getNoActionRequiredPPAPPhase : ' + resp4);
								console.log('getNoActionRequiredPPAPPhase JSON Resp : ' + JSON.stringify(resp4))
								getNoActionRequiredPPAPPhase = resp4;
								// @ts-ignore
								const allPPAPPhase1: AllPPAPPhase = new AllPPAPPhase();

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0Next15Days = getBeyondSixPPAPPhase.phase0Next15Days + getPastDuePPAPPhase.phase0Next15Days + getNextSixPPAPPhase.phase0Next15Days + getPartsUntimedPPAPPhase.phase0Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1Next15Days = getBeyondSixPPAPPhase.phase1Next15Days + getPastDuePPAPPhase.phase1Next15Days + getNextSixPPAPPhase.phase1Next15Days + getPartsUntimedPPAPPhase.phase1Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2Next15Days = getBeyondSixPPAPPhase.phase2Next15Days + getPastDuePPAPPhase.phase2Next15Days + getNextSixPPAPPhase.phase2Next15Days + getPartsUntimedPPAPPhase.phase2Next15Days;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3Next15Days = getBeyondSixPPAPPhase.phase3Next15Days + getPastDuePPAPPhase.phase3Next15Days + getNextSixPPAPPhase.phase3Next15Days + getPartsUntimedPPAPPhase.phase3Next15Days;

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PlanSubmission = getBeyondSixPPAPPhase.phase0PlanSubmission + getPastDuePPAPPhase.phase0PlanSubmission + getNextSixPPAPPhase.phase0PlanSubmission + getPartsUntimedPPAPPhase.phase0PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PlanSubmission = getBeyondSixPPAPPhase.phase1PlanSubmission + getPastDuePPAPPhase.phase1PlanSubmission + getNextSixPPAPPhase.phase1PlanSubmission + getPartsUntimedPPAPPhase.phase1PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PlanSubmission = getBeyondSixPPAPPhase.phase2PlanSubmission + getPastDuePPAPPhase.phase2PlanSubmission + getNextSixPPAPPhase.phase2PlanSubmission + getPartsUntimedPPAPPhase.phase2PlanSubmission;
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PlanSubmission = getBeyondSixPPAPPhase.phase3PlanSubmission + getPastDuePPAPPhase.phase3PlanSubmission + getNextSixPPAPPhase.phase3PlanSubmission + getPartsUntimedPPAPPhase.phase3PlanSubmission;

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0SubOrApprovalPending = getBeyondSixPPAPPhase.phase0SubOrApprovalPending + getPastDuePPAPPhase.phase0SubOrApprovalPending + getNextSixPPAPPhase.phase0SubOrApprovalPending + getPartsUntimedPPAPPhase.phase0SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1SubOrApprovalPending = getBeyondSixPPAPPhase.phase1SubOrApprovalPending + getPastDuePPAPPhase.phase1SubOrApprovalPending + getNextSixPPAPPhase.phase1SubOrApprovalPending + getPartsUntimedPPAPPhase.phase1SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2SubOrApprovalPending = getBeyondSixPPAPPhase.phase2SubOrApprovalPending + getPastDuePPAPPhase.phase2SubOrApprovalPending + getNextSixPPAPPhase.phase2SubOrApprovalPending + getPartsUntimedPPAPPhase.phase2SubOrApprovalPending
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3SubOrApprovalPending = getBeyondSixPPAPPhase.phase3SubOrApprovalPending + getPastDuePPAPPhase.phase3SubOrApprovalPending + getNextSixPPAPPhase.phase3SubOrApprovalPending + getPartsUntimedPPAPPhase.phase3SubOrApprovalPending

							//Added for STA/PD Dashboard changes
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase0AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase1AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase2AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalSTA
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase3AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalSTA

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalPD + getPastDuePPAPPhase.phase0AwaitingMyApprovalPD + getNextSixPPAPPhase.phase0AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalPD + getPastDuePPAPPhase.phase1AwaitingMyApprovalPD + getNextSixPPAPPhase.phase1AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalPD + getPastDuePPAPPhase.phase2AwaitingMyApprovalPD + getNextSixPPAPPhase.phase2AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalPD
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalPD + getPastDuePPAPPhase.phase3AwaitingMyApprovalPD + getNextSixPPAPPhase.phase3AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalPD

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0SupplierCausedSlip = getBeyondSixPPAPPhase.phase0SupplierCausedSlip + getPastDuePPAPPhase.phase0SupplierCausedSlip + getNextSixPPAPPhase.phase0SupplierCausedSlip + getPartsUntimedPPAPPhase.phase0SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1SupplierCausedSlip = getBeyondSixPPAPPhase.phase1SupplierCausedSlip + getPastDuePPAPPhase.phase1SupplierCausedSlip + getNextSixPPAPPhase.phase1SupplierCausedSlip + getPartsUntimedPPAPPhase.phase1SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2SupplierCausedSlip = getBeyondSixPPAPPhase.phase2SupplierCausedSlip + getPastDuePPAPPhase.phase2SupplierCausedSlip + getNextSixPPAPPhase.phase2SupplierCausedSlip + getPartsUntimedPPAPPhase.phase2SupplierCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3SupplierCausedSlip = getBeyondSixPPAPPhase.phase3SupplierCausedSlip + getPastDuePPAPPhase.phase3SupplierCausedSlip + getNextSixPPAPPhase.phase3SupplierCausedSlip + getPartsUntimedPPAPPhase.phase3SupplierCausedSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PDCausedSlip = getBeyondSixPPAPPhase.phase0PDCausedSlip + getPastDuePPAPPhase.phase0PDCausedSlip + getNextSixPPAPPhase.phase0PDCausedSlip + getPartsUntimedPPAPPhase.phase0PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PDCausedSlip = getBeyondSixPPAPPhase.phase1PDCausedSlip + getPastDuePPAPPhase.phase1PDCausedSlip + getNextSixPPAPPhase.phase1PDCausedSlip + getPartsUntimedPPAPPhase.phase1PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PDCausedSlip = getBeyondSixPPAPPhase.phase2PDCausedSlip + getPastDuePPAPPhase.phase2PDCausedSlip + getNextSixPPAPPhase.phase2PDCausedSlip + getPartsUntimedPPAPPhase.phase2PDCausedSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PDCausedSlip = getBeyondSixPPAPPhase.phase3PDCausedSlip + getPastDuePPAPPhase.phase3PDCausedSlip + getNextSixPPAPPhase.phase3PDCausedSlip + getPartsUntimedPPAPPhase.phase3PDCausedSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0PurchaseSlip = getBeyondSixPPAPPhase.phase0PurchaseSlip + getPastDuePPAPPhase.phase0PurchaseSlip + getNextSixPPAPPhase.phase0PurchaseSlip + getPartsUntimedPPAPPhase.phase0PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1PurchaseSlip = getBeyondSixPPAPPhase.phase1PurchaseSlip + getPastDuePPAPPhase.phase1PurchaseSlip + getNextSixPPAPPhase.phase1PurchaseSlip + getPartsUntimedPPAPPhase.phase1PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2PurchaseSlip = getBeyondSixPPAPPhase.phase2PurchaseSlip + getPastDuePPAPPhase.phase2PurchaseSlip + getNextSixPPAPPhase.phase2PurchaseSlip + getPartsUntimedPPAPPhase.phase2PurchaseSlip
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3PurchaseSlip = getBeyondSixPPAPPhase.phase3PurchaseSlip + getPastDuePPAPPhase.phase3PurchaseSlip + getNextSixPPAPPhase.phase3PurchaseSlip + getPartsUntimedPPAPPhase.phase3PurchaseSlip

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0OtherSlips = getBeyondSixPPAPPhase.phase0OtherSlips + getPastDuePPAPPhase.phase0OtherSlips + getNextSixPPAPPhase.phase0OtherSlips + getPartsUntimedPPAPPhase.phase0OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1OtherSlips = getBeyondSixPPAPPhase.phase1OtherSlips + getPastDuePPAPPhase.phase1OtherSlips + getNextSixPPAPPhase.phase1OtherSlips + getPartsUntimedPPAPPhase.phase1OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2OtherSlips = getBeyondSixPPAPPhase.phase2OtherSlips + getPastDuePPAPPhase.phase2OtherSlips + getNextSixPPAPPhase.phase2OtherSlips + getPartsUntimedPPAPPhase.phase2OtherSlips
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3OtherSlips = getBeyondSixPPAPPhase.phase3OtherSlips + getPastDuePPAPPhase.phase3OtherSlips + getNextSixPPAPPhase.phase3OtherSlips + getPartsUntimedPPAPPhase.phase3OtherSlips

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0RejectedPSW = getBeyondSixPPAPPhase.phase0RejectedPSW + getPastDuePPAPPhase.phase0RejectedPSW + getNextSixPPAPPhase.phase0RejectedPSW + getPartsUntimedPPAPPhase.phase0RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1RejectedPSW = getBeyondSixPPAPPhase.phase1RejectedPSW + getPastDuePPAPPhase.phase1RejectedPSW + getNextSixPPAPPhase.phase1RejectedPSW + getPartsUntimedPPAPPhase.phase1RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2RejectedPSW = getBeyondSixPPAPPhase.phase2RejectedPSW + getPastDuePPAPPhase.phase2RejectedPSW + getNextSixPPAPPhase.phase2RejectedPSW + getPartsUntimedPPAPPhase.phase2RejectedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3RejectedPSW = getBeyondSixPPAPPhase.phase3RejectedPSW + getPastDuePPAPPhase.phase3RejectedPSW + getNextSixPPAPPhase.phase3RejectedPSW + getPartsUntimedPPAPPhase.phase3RejectedPSW

							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0ApprovedPSW = getBeyondSixPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1ApprovedPSW = getBeyondSixPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2ApprovedPSW = getBeyondSixPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3ApprovedPSW = getBeyondSixPPAPPhase.phase3ApprovedPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW
							//Added for US3832060
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase0NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase0NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase1NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase1NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase2NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase2NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
							// tslint:disable-next-line:max-line-length
							allPPAPPhase1.phase3NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase3NoActionRequiredPSW
							// tslint:disable-next-line:max-line-length
							// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW

								this.allPPAPPhase = allPPAPPhase1;

							});
						});
					});
				});
			});
		//});
	}

	getPrgmBeyondSixPPAPPhase(pgmCode : any, siteCodes: any) {
		this.epswHomepageService.getPrgmBeyondSixPPAPPhase(pgmCode, this.ppapOption.name,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for BeyondSixPPAPPhase : '+resp);
			console.log('JSON Response for BeyondSixPPAPPhase : '+JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getPrgmPastDuePPAPPhase(pgmCode : any, siteCodes: any) {
		this.epswHomepageService.getPrgmPastDuePPAPPhase(pgmCode, this.ppapOption.name,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for PastDuePPAPPhase : ' + resp);
			console.log('JSON Response for PastDuePPAPPhase : ' + JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getPrgmNextSixPPAPPhase(pgmCode: any, siteCodes: any) {
		this.epswHomepageService.getPrgmNextSixPPAPPhase(pgmCode, this.ppapOption.name,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for NextSixPPAPPhase : ' + resp);
			this.allPPAPPhase = resp;
		});
	}

	getPrgmPartsUntimedPPAPPhase(pgmCode: any, siteCodes: any) {
		this.epswHomepageService.getPrgmPartsUntimedPPAPPhase(pgmCode, this.ppapOption.name,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for PartsUntimedPPAPPhase : ' + resp);
			this.allPPAPPhase = resp;
		});
	}

	private getProgramSiteTreeMapValues(programCodes: any,siteList) {
		// tslint:disable-next-line:max-line-length
		if(programCodes !== undefined && programCodes !== null) {
			this.epswHomepageService.getPgmSiteBeyondSixMonthValues(programCodes, siteList, sessionStorage.getItem('userId').toUpperCase()).subscribe(resp => {
				console.log('Response for Beyond Six Month : ' + resp);
				this.beyondSixMonths = resp;
				// tslint:disable-next-line:max-line-length
				this.epswHomepageService.getPgmSitePastDueValues(programCodes, siteList, sessionStorage.getItem('userId').toUpperCase()).subscribe(pastDueResp => {
					console.log('Response for Past Due : ' + pastDueResp);
					this.pastDue = pastDueResp;
					// tslint:disable-next-line:max-line-length
					this.epswHomepageService.getPgmSiteNextSixMonthValues(programCodes, siteList, sessionStorage.getItem('userId').toUpperCase()).subscribe(nextSixMonthResp => {
						console.log('Response for Next Six Months : ' + nextSixMonthResp);
						this.nextSixMonths = nextSixMonthResp;
						// tslint:disable-next-line:max-line-length
						this.epswHomepageService.getPgmSitePartsUntimedValues(programCodes, siteList, sessionStorage.getItem('userId').toUpperCase()).subscribe(vppUntimedResp => {
							console.log('Response for VPP Untimed : ' + vppUntimedResp);
							this.vppUntimed = vppUntimedResp;
							// this.totalPartCount = this.beyondSixMonths + this.pastDue + this.nextSixMonths + this.vppUntimed;
							this.updateHighChart('yes', this.beyondSixMonths, this.nextSixMonths, this.vppUntimed, this.pastDue);
						});
					});
				});
			});
		}
	}

	// Adding All Option in PPAP Timing
	getAllPrgmSitePPAPTiming(programCode: string[],siteList) {
		let getBeyondSixPPAPPhase : any;
		let getPastDuePPAPPhase : any;
		let getNextSixPPAPPhase : any;
		let getPartsUntimedPPAPPhase : any;
		let getNoActionRequiredPPAPPhase : any;
		// tslint:disable-next-line:max-line-length
		if(programCode !== undefined && programCode !== null) {
			this.epswHomepageService.getPrgmSiteBeyondSixPPAPPhase(programCode, siteList, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
				console.log('Response for BeyondSixPPAPPhase : ' + resp);
				console.log('BeyondSixPPAPPhase JSON Resp : ' + JSON.stringify(resp))
				getBeyondSixPPAPPhase = resp;
				this.epswHomepageService.getPrgmSitePastDuePPAPPhase(programCode, siteList, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp1 => {
					console.log('Response for PastDuePPAPPhase : ' + resp1);
					console.log('PastDuePPAPPhase get JSON Resp : ' + JSON.stringify(resp1))
					console.log('User Role : ' + sessionStorage.getItem('userId').toUpperCase())
					getPastDuePPAPPhase = resp1;
					this.epswHomepageService.getPrgmSiteNextSixPPAPPhase(programCode, siteList, sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp2 => {
						console.log('Response for NextSixPPAPPhase : ' + resp2);
						console.log('NextSixPPAPPhase JSON Resp : ' + JSON.stringify(resp2))
						getNextSixPPAPPhase = resp2;
						this.epswHomepageService.getPrgmSitePartsUntimedPPAPPhase(programCode, siteList, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp3 => {
							console.log('Response for PartsUntimedPPAPPhase : ' + resp3);
							console.log('PartsUntimedPPAPPhase JSON Resp : ' + JSON.stringify(resp3))
							getPartsUntimedPPAPPhase = resp3;
							this.epswHomepageService.getPrgmSiteNoActionRequiredPPAPPhase(programCode, siteList, sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp4 => {
								console.log('Response for getNoActionRequiredPPAPPhase : ' + resp4);
								console.log('getNoActionRequiredPPAPPhase JSON Resp : ' + JSON.stringify(resp4))
								getNoActionRequiredPPAPPhase = resp4;
								// @ts-ignore
								const allPPAPPhase1: AllPPAPPhase = new AllPPAPPhase();

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0Next15Days = getBeyondSixPPAPPhase.phase0Next15Days + getPastDuePPAPPhase.phase0Next15Days + getNextSixPPAPPhase.phase0Next15Days + getPartsUntimedPPAPPhase.phase0Next15Days;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1Next15Days = getBeyondSixPPAPPhase.phase1Next15Days + getPastDuePPAPPhase.phase1Next15Days + getNextSixPPAPPhase.phase1Next15Days + getPartsUntimedPPAPPhase.phase1Next15Days;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2Next15Days = getBeyondSixPPAPPhase.phase2Next15Days + getPastDuePPAPPhase.phase2Next15Days + getNextSixPPAPPhase.phase2Next15Days + getPartsUntimedPPAPPhase.phase2Next15Days;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3Next15Days = getBeyondSixPPAPPhase.phase3Next15Days + getPastDuePPAPPhase.phase3Next15Days + getNextSixPPAPPhase.phase3Next15Days + getPartsUntimedPPAPPhase.phase3Next15Days;

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0PlanSubmission = getBeyondSixPPAPPhase.phase0PlanSubmission + getPastDuePPAPPhase.phase0PlanSubmission + getNextSixPPAPPhase.phase0PlanSubmission + getPartsUntimedPPAPPhase.phase0PlanSubmission;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1PlanSubmission = getBeyondSixPPAPPhase.phase1PlanSubmission + getPastDuePPAPPhase.phase1PlanSubmission + getNextSixPPAPPhase.phase1PlanSubmission + getPartsUntimedPPAPPhase.phase1PlanSubmission;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2PlanSubmission = getBeyondSixPPAPPhase.phase2PlanSubmission + getPastDuePPAPPhase.phase2PlanSubmission + getNextSixPPAPPhase.phase2PlanSubmission + getPartsUntimedPPAPPhase.phase2PlanSubmission;
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3PlanSubmission = getBeyondSixPPAPPhase.phase3PlanSubmission + getPastDuePPAPPhase.phase3PlanSubmission + getNextSixPPAPPhase.phase3PlanSubmission + getPartsUntimedPPAPPhase.phase3PlanSubmission;

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0SubOrApprovalPending = getBeyondSixPPAPPhase.phase0SubOrApprovalPending + getPastDuePPAPPhase.phase0SubOrApprovalPending + getNextSixPPAPPhase.phase0SubOrApprovalPending + getPartsUntimedPPAPPhase.phase0SubOrApprovalPending
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1SubOrApprovalPending = getBeyondSixPPAPPhase.phase1SubOrApprovalPending + getPastDuePPAPPhase.phase1SubOrApprovalPending + getNextSixPPAPPhase.phase1SubOrApprovalPending + getPartsUntimedPPAPPhase.phase1SubOrApprovalPending
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2SubOrApprovalPending = getBeyondSixPPAPPhase.phase2SubOrApprovalPending + getPastDuePPAPPhase.phase2SubOrApprovalPending + getNextSixPPAPPhase.phase2SubOrApprovalPending + getPartsUntimedPPAPPhase.phase2SubOrApprovalPending
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3SubOrApprovalPending = getBeyondSixPPAPPhase.phase3SubOrApprovalPending + getPastDuePPAPPhase.phase3SubOrApprovalPending + getNextSixPPAPPhase.phase3SubOrApprovalPending + getPartsUntimedPPAPPhase.phase3SubOrApprovalPending

								//Added for STA/PD Dashboard changes
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase0AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase0AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalSTA
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase1AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase1AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalSTA
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase2AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase2AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalSTA
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3AwaitingMyApprovalSTA = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPastDuePPAPPhase.phase3AwaitingMyApprovalSTA + getNextSixPPAPPhase.phase3AwaitingMyApprovalSTA + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalSTA

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase0AwaitingMyApprovalPD + getPastDuePPAPPhase.phase0AwaitingMyApprovalPD + getNextSixPPAPPhase.phase0AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase0AwaitingMyApprovalPD
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase1AwaitingMyApprovalPD + getPastDuePPAPPhase.phase1AwaitingMyApprovalPD + getNextSixPPAPPhase.phase1AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase1AwaitingMyApprovalPD
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase2AwaitingMyApprovalPD + getPastDuePPAPPhase.phase2AwaitingMyApprovalPD + getNextSixPPAPPhase.phase2AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase2AwaitingMyApprovalPD
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3AwaitingMyApprovalPD = getBeyondSixPPAPPhase.phase3AwaitingMyApprovalPD + getPastDuePPAPPhase.phase3AwaitingMyApprovalPD + getNextSixPPAPPhase.phase3AwaitingMyApprovalPD + getPartsUntimedPPAPPhase.phase3AwaitingMyApprovalPD

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0SupplierCausedSlip = getBeyondSixPPAPPhase.phase0SupplierCausedSlip + getPastDuePPAPPhase.phase0SupplierCausedSlip + getNextSixPPAPPhase.phase0SupplierCausedSlip + getPartsUntimedPPAPPhase.phase0SupplierCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1SupplierCausedSlip = getBeyondSixPPAPPhase.phase1SupplierCausedSlip + getPastDuePPAPPhase.phase1SupplierCausedSlip + getNextSixPPAPPhase.phase1SupplierCausedSlip + getPartsUntimedPPAPPhase.phase1SupplierCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2SupplierCausedSlip = getBeyondSixPPAPPhase.phase2SupplierCausedSlip + getPastDuePPAPPhase.phase2SupplierCausedSlip + getNextSixPPAPPhase.phase2SupplierCausedSlip + getPartsUntimedPPAPPhase.phase2SupplierCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3SupplierCausedSlip = getBeyondSixPPAPPhase.phase3SupplierCausedSlip + getPastDuePPAPPhase.phase3SupplierCausedSlip + getNextSixPPAPPhase.phase3SupplierCausedSlip + getPartsUntimedPPAPPhase.phase3SupplierCausedSlip

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0PDCausedSlip = getBeyondSixPPAPPhase.phase0PDCausedSlip + getPastDuePPAPPhase.phase0PDCausedSlip + getNextSixPPAPPhase.phase0PDCausedSlip + getPartsUntimedPPAPPhase.phase0PDCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1PDCausedSlip = getBeyondSixPPAPPhase.phase1PDCausedSlip + getPastDuePPAPPhase.phase1PDCausedSlip + getNextSixPPAPPhase.phase1PDCausedSlip + getPartsUntimedPPAPPhase.phase1PDCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2PDCausedSlip = getBeyondSixPPAPPhase.phase2PDCausedSlip + getPastDuePPAPPhase.phase2PDCausedSlip + getNextSixPPAPPhase.phase2PDCausedSlip + getPartsUntimedPPAPPhase.phase2PDCausedSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3PDCausedSlip = getBeyondSixPPAPPhase.phase3PDCausedSlip + getPastDuePPAPPhase.phase3PDCausedSlip + getNextSixPPAPPhase.phase3PDCausedSlip + getPartsUntimedPPAPPhase.phase3PDCausedSlip

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0PurchaseSlip = getBeyondSixPPAPPhase.phase0PurchaseSlip + getPastDuePPAPPhase.phase0PurchaseSlip + getNextSixPPAPPhase.phase0PurchaseSlip + getPartsUntimedPPAPPhase.phase0PurchaseSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1PurchaseSlip = getBeyondSixPPAPPhase.phase1PurchaseSlip + getPastDuePPAPPhase.phase1PurchaseSlip + getNextSixPPAPPhase.phase1PurchaseSlip + getPartsUntimedPPAPPhase.phase1PurchaseSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2PurchaseSlip = getBeyondSixPPAPPhase.phase2PurchaseSlip + getPastDuePPAPPhase.phase2PurchaseSlip + getNextSixPPAPPhase.phase2PurchaseSlip + getPartsUntimedPPAPPhase.phase2PurchaseSlip
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3PurchaseSlip = getBeyondSixPPAPPhase.phase3PurchaseSlip + getPastDuePPAPPhase.phase3PurchaseSlip + getNextSixPPAPPhase.phase3PurchaseSlip + getPartsUntimedPPAPPhase.phase3PurchaseSlip

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0OtherSlips = getBeyondSixPPAPPhase.phase0OtherSlips + getPastDuePPAPPhase.phase0OtherSlips + getNextSixPPAPPhase.phase0OtherSlips + getPartsUntimedPPAPPhase.phase0OtherSlips
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1OtherSlips = getBeyondSixPPAPPhase.phase1OtherSlips + getPastDuePPAPPhase.phase1OtherSlips + getNextSixPPAPPhase.phase1OtherSlips + getPartsUntimedPPAPPhase.phase1OtherSlips
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2OtherSlips = getBeyondSixPPAPPhase.phase2OtherSlips + getPastDuePPAPPhase.phase2OtherSlips + getNextSixPPAPPhase.phase2OtherSlips + getPartsUntimedPPAPPhase.phase2OtherSlips
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3OtherSlips = getBeyondSixPPAPPhase.phase3OtherSlips + getPastDuePPAPPhase.phase3OtherSlips + getNextSixPPAPPhase.phase3OtherSlips + getPartsUntimedPPAPPhase.phase3OtherSlips

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0RejectedPSW = getBeyondSixPPAPPhase.phase0RejectedPSW + getPastDuePPAPPhase.phase0RejectedPSW + getNextSixPPAPPhase.phase0RejectedPSW + getPartsUntimedPPAPPhase.phase0RejectedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1RejectedPSW = getBeyondSixPPAPPhase.phase1RejectedPSW + getPastDuePPAPPhase.phase1RejectedPSW + getNextSixPPAPPhase.phase1RejectedPSW + getPartsUntimedPPAPPhase.phase1RejectedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2RejectedPSW = getBeyondSixPPAPPhase.phase2RejectedPSW + getPastDuePPAPPhase.phase2RejectedPSW + getNextSixPPAPPhase.phase2RejectedPSW + getPartsUntimedPPAPPhase.phase2RejectedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3RejectedPSW = getBeyondSixPPAPPhase.phase3RejectedPSW + getPastDuePPAPPhase.phase3RejectedPSW + getNextSixPPAPPhase.phase3RejectedPSW + getPartsUntimedPPAPPhase.phase3RejectedPSW

								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0ApprovedPSW = getBeyondSixPPAPPhase.phase0ApprovedPSW
								// tslint:disable-next-line:max-line-length
								//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1ApprovedPSW = getBeyondSixPPAPPhase.phase1ApprovedPSW
								// tslint:disable-next-line:max-line-length
								//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2ApprovedPSW = getBeyondSixPPAPPhase.phase2ApprovedPSW
								// tslint:disable-next-line:max-line-length
								// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3ApprovedPSW = getBeyondSixPPAPPhase.phase3ApprovedPSW
								// tslint:disable-next-line:max-line-length
								// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW
								//Added for US3832060
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase0NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase0NoActionRequiredPSW
								// tslint:disable-next-line:max-line-length
								//+   getPastDuePPAPPhase.phase0ApprovedPSW          +    getNextSixPPAPPhase.phase0ApprovedPSW          +  getPartsUntimedPPAPPhase.phase0ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase1NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase1NoActionRequiredPSW
								// tslint:disable-next-line:max-line-length
								//+   getPastDuePPAPPhase.phase1ApprovedPSW          +    getNextSixPPAPPhase.phase1ApprovedPSW          +  getPartsUntimedPPAPPhase.phase1ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase2NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase2NoActionRequiredPSW
								// tslint:disable-next-line:max-line-length
								// +   getPastDuePPAPPhase.phase2ApprovedPSW          +    getNextSixPPAPPhase.phase2ApprovedPSW          +  getPartsUntimedPPAPPhase.phase2ApprovedPSW
								// tslint:disable-next-line:max-line-length
								allPPAPPhase1.phase3NoActionRequiredPSW = getNoActionRequiredPPAPPhase.phase3NoActionRequiredPSW
								// tslint:disable-next-line:max-line-length
								// +   getPastDuePPAPPhase.phase3ApprovedPSW          +    getNextSixPPAPPhase.phase3ApprovedPSW          +  getPartsUntimedPPAPPhase.phase3ApprovedPSW

								this.allPPAPPhase = allPPAPPhase1;

							});
						});
					});
				});
			});
		}
	}

	getPrgmSiteBeyondSixPPAPPhase(programCodeList : any) {
		this.epswHomepageService.getPrgmSiteBeyondSixPPAPPhase(programCodeList, this.siteList,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for BeyondSixPPAPPhase : '+resp);
			console.log('JSON Response for BeyondSixPPAPPhase : '+JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getPrgmSitePastDuePPAPPhase(programCodeList : any) {
		this.epswHomepageService.getPrgmSitePastDuePPAPPhase(programCodeList, this.siteList,
			sessionStorage.getItem('userId').toUpperCase(), this.priorityType.code).subscribe(resp => {
			console.log('Response for PastDuePPAPPhase : ' + resp);
			console.log('JSON Response for PastDuePPAPPhase : ' + JSON.stringify(resp));
			this.allPPAPPhase = resp;
		});
	}

	getPrgmSiteNextSixPPAPPhase(programCodeList: any) {
		this.epswHomepageService.getPrgmSiteNextSixPPAPPhase(programCodeList, this.siteList,
			sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp => {
			console.log('Response for NextSixPPAPPhase : ' + resp);
			this.allPPAPPhase = resp;
		});
	}

	getPrgmSitePartsUntimedPPAPPhase(programCodeList: any) {
		this.epswHomepageService.getPrgmSitePartsUntimedPPAPPhase(programCodeList, this.siteList,
			sessionStorage.getItem('userId').toUpperCase(),this.priorityType.code).subscribe(resp => {
			console.log('Response for PartsUntimedPPAPPhase : ' + resp);
			this.allPPAPPhase = resp;
		});
	}

	getPriorityStatus(ppapPartsArray: any, ppapPackageIdentifierSave: PpapPackageIdentifier) {
		let loopVal = 0;
		for (const item of ppapPartsArray) {
			loopVal += 1;
			this.digitalPpapPackageService.getPriorityStatus(item.programCode, ppapPackageIdentifierSave.siteCode, item.noPartBase).subscribe(resp => {
				console.log('7490',resp);
				//resp = 'N';
				this.priorityListResp.push(resp['status']);
				if (this.priorityListResp.includes('Y')) {
					ppapPackageIdentifierSave.familyPriorityStatus = 'Yes';
					item.priorityStatus = 'Yes';
					console.log('enter afetr loop 1',ppapPackageIdentifierSave);
					ppapPackageIdentifierSave.addOrUpdateParts=false;
					this.epswHomepageService.saveData(ppapPackageIdentifierSave).subscribe(resp => {
						console.log('Save Data: ' + resp);
						this.ppapPackageIdentifierSave = resp;
						this.chartPpapElementSection();
					});
				} else if (this.priorityListResp.includes(null)) {
					console.log(this.priorityListResp.length);
					ppapPackageIdentifierSave.familyPriorityStatus = '';
					item.priorityStatus = '';
					console.log('enter afetr loop 2',ppapPackageIdentifierSave.familyPriorityStatus);
				} else {
					ppapPackageIdentifierSave.familyPriorityStatus = 'No';
					item.priorityStatus = 'No';
					console.log('enter afetr loop 3',ppapPackageIdentifierSave.familyPriorityStatus);
				}
				console.log('enter afetr loop 4',ppapPackageIdentifierSave);
				if (loopVal === ppapPartsArray.length) {
					console.log('7509',ppapPackageIdentifierSave);
					ppapPackageIdentifierSave.addOrUpdateParts=false;
					this.epswHomepageService.saveData(ppapPackageIdentifierSave).subscribe(resp => {
						console.log('Save Data: ' + resp);
						this.ppapPackageIdentifierSave = resp;
						this.chartPpapElementSection();
					});
				}
			});
			if (ppapPackageIdentifierSave.familyPriorityStatus === 'Yes') {
				break;
			}
		}
	}

	excelDownload() {
		console.log('Download Excel');
		this.siteCodes = [];
		let program='';
		let identifyProgramOrSiteCode  = '';
		if(this.checked1) {
			  program = sessionStorage.getItem('programDropdown');
			  this.siteCodes.push(program);
			  identifyProgramOrSiteCode = 'Program';
		}else {
			if(this.siteOption === 'ALL') {
				this.siteCodeList.forEach(item => {
					this.siteCodes.push(item);
				})
			}else {
				this.siteCodes.push(sessionStorage.getItem('siteOption'));
			}
			identifyProgramOrSiteCode = 'Sitecode';

		}
		// if(this.userRole === 'ePSW Admin' || this.userRole === 'STASrProgramManager' || this.userRole === 'STAProgramEngineer'
		// 	|| this.userRole === 'STAProgramManager' || this.userRole === 'STASuperUser') {
		// 	this.siteValues = [];
		// 	this.site.forEach(sitecodes => {
		// 		this.siteValues.push(sitecodes.value);
		// 	});
		// }
		this.epswHomepageService.getExcelData(this.siteCodes,identifyProgramOrSiteCode);
	}
}
