import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from '../config';

@Injectable({
  providedIn: 'root'
})
export class AdhocReportService {

	private config = new Config();
	private httpUrlGetSTACdsids = `${this.config.url}/epsw/adhocReport/getStaCDSIDs`;
	private httpUrlGetSTASiteData =`${this.config.url}/epsw/adhocReport/bySiteEngineerProgram`;



	constructor(private httpClient: HttpClient) {
	}

  getSTACDSIDs(staRole) {
	  console.log('getSTACDSIDs call Start');
	  const params = new HttpParams().set('staRole', staRole);
	  return this.httpClient.get<any>(this.httpUrlGetSTACdsids,{params,responseType:'json'});
	  //return this.httpClient.post<string[]>(this.httpUrlGetSTACdsids,{params});
	  console.log('getSTACDSIDs call Call End');
  }

	generateExcelForSTASiteEngineer(staSiteEngineerCdsId: any) {
		const params = new HttpParams().set('staSiteEngineerCdsId', staSiteEngineerCdsId);
		this.httpClient.get(this.httpUrlGetSTASiteData, {params,responseType:'blob'})
			.subscribe((response)=> {
				this.downloadExcelFile(response,'text/csv')
			});
	}

	private downloadExcelFile(response: Blob, type: string) {
		const blob = new Blob([response], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'PPAP Adhoc Report.xlsm';
		anchor.href = url;
		anchor.click();
	}
}
